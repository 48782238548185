import {
	// 1. collectionReducer
	CLEAR_COLLECTION,
	SET_COLLECTION_CATEGORIES,
	SET_COLLECTION_DATA,
	SET_COLLECTION_NAME,
	SET_COLLECTION_SELECTED_KEYWORD,

	// 2. errorReducer
	ADD_ERROR,
	ADD_HTTP_ERROR,
	ALLOW_ERROR,
	PREVENT_ERROR,

	// 3. modalReducer
	CLOSE_MODAL,
	END_MODAL_LOADING,
	OPEN_MODAL,
	START_MODAL_LOADING,

	// 4. responsiveReducer
	SET_RESPONSIVE,
	TOGGLE_RESPONSIVE,

	// 5. userReducer
	ACTIVATE_ASSET,
	ADD_FAVORITE,
	DEACTIVATE_ASSET,
	LOGOUT_USER,
	REMOVE_FAVORITE,
	RESUBSCRIBE_USER,
	SET_CURRENT_USER,
	SUBSCRIBE_USER,
	UNSUBSCRIBE_USER,

	SUBSCRIPTION_TYPE
} from './types.js';

// 1. collectionReducer
export const clearCollection = () => {
	return {
		type: CLEAR_COLLECTION,
	};
};

export const setCollectionCategories = categories => {
	return {
		payload: categories,
		type: SET_COLLECTION_CATEGORIES,
	};
};

export const setCollectionData = data => {
	return {
		payload: data,
		type: SET_COLLECTION_DATA,
	};
};

export const setCollectionName = name => {
	return {
		payload: name,
		type: SET_COLLECTION_NAME,
	};
};

export const setCollectionSelectedKeyword = selectedKeyword => {
	return {
		payload: selectedKeyword,
		type: SET_COLLECTION_SELECTED_KEYWORD,
	};
};

// 2. errorReducer
export const addError = () => ({
	type: ADD_ERROR,
});

export const addHttpError = () => ({
	type: ADD_HTTP_ERROR,
});

export const allowError = () => ({
	type: ALLOW_ERROR,
});

export const preventError = () => ({
	type: PREVENT_ERROR,
});

// 3. modalReducer
export const closeModal = () => ({
	type: CLOSE_MODAL,
});

export const endModalLoading = () => ({
	type: END_MODAL_LOADING,
});

export const openModal = () => ({
	type: OPEN_MODAL,
});

export const startModalLoading = () => ({
	type: START_MODAL_LOADING,
});

// 4. responsiveReducer
export const setResponsive = layout => ({
	payload: layout,
	type: SET_RESPONSIVE,
});

export const toggleResponsive = layout => ({
	payload: layout,
	type: TOGGLE_RESPONSIVE,
});

// 5. userReducer
export const activateAsset = assetData => ({
	payload: assetData,
	type: ACTIVATE_ASSET,
});

export const addFavorite = assetName => ({
	payload: assetName,
	type: ADD_FAVORITE,
});

export const setCurrentUser = userData => {
	console.log('setCurrentUser()');
	console.log('userData: ', userData);

	return {
		payload: userData,
		type: SET_CURRENT_USER,
	};
};

export const deactivateAsset = assetName => ({
	payload: assetName,
	type: DEACTIVATE_ASSET,
});

export const logoutUser = () => ({
	type: LOGOUT_USER,
});

export const removeFavorite = assetName => ({
	payload: assetName,
	type: REMOVE_FAVORITE,
});



export const subscribeUser = subscriptionData => {
	const subsciptionData = subscriptionData;
	const subscriptionID  = subscriptionData.subscriptionID;

	return {
		payload: {
			subsciptionData: subsciptionData,
			subscriptionID: subscriptionID,
		},
		type: SUBSCRIBE_USER,
	};
};

export const unsubscribeUser = subscriptionID => {
	console.log('unsubscribeUser()');
	console.log('subscriptionID: ', subscriptionID);

	return {
		// payload: user,
		type: UNSUBSCRIBE_USER,
	};
};

export const subscriptionType = subscriptionType => {

	return {
		payload: subscriptionType,
		type: SUBSCRIPTION_TYPE,
	};
}
