// Libraries
import React from 'react';

// Stylesheets
import './VideoCard.scss';

const VideoCard = props => {
	const {
		description,
		thumbnailSrc,
		videoSrc,
	} = props;

	return (
		<div className='video-card'>
			<video
				controls
				poster={thumbnailSrc}
				src={videoSrc}
				width='350'
			>
				Sorry, your browser doesn't support HTML5 <code>video</code>, but you can download this video from the <a href='https://archive.org/details/Popeye_forPresident' target='_blank'>Internet Archive</a>.
			</video>
			<div className='description'>{description}</div>
		</div>
	);
};

export default VideoCard;