// Libraries
import React from 'react';

// Stylesheets
import './LoadingSpinner.scss';

const LoadingSpinner = props => {
	return (
		<div className='loading-spinner'>
			<div className='one'>
				<div className='lds-dual-ring'></div>
			</div>
		</div>
	);
};

export default LoadingSpinner;