// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Methods
import {
	_,
} from '../../lib/index';

import { clearCollection } from '../../redux/actions.js';

// Stylesheets
import './Header.scss';

// VICTOR - onlcik of any of these, clear collection data

const Header = props => {

	const handleHeaderClick = e => {
		let target = e.target;

		if (!target) return;

		let resetData = target.getAttribute('data-reset');

		if (!resetData) return;

		// Clear any pre-exisiting collection information
		props.clearCollection();
	};

	return (
		<div
			className='header'
			onClick={(e) => handleHeaderClick(e)}
		>

			<div className='header-home'>
				<Link
					data-reset={true}
					to='/'
				>
					{_('640 Studio')}
				</Link>
			</div>

			<div className='header-tutorials'>
				<Link
					data-reset={true}
					to='/tutorials'
				>
					<i className='fa-solid fa-chalkboard-user'></i>
					{_('Tutorials')}
				</Link>
			</div>

			{/* VICTOR - Hiding this for now, come back and fix this */}
			{/* <div className='header-membership'>
				<Link
					data-reset={true}
					to='/lifetime-membership'
				>
					<i className='fa-solid fa-heart'></i>
					{_('Get Free Lifetime Membership')}
				</Link>
			</div> */}

		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		clearCollection,
	}, dispatch);
};

export default connect(null, mapDispatchToProps)(Header);