// Libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Components
import PayPal from "../../components/paypal/PayPal";
// Stripe, Venmo, Other Payment Options..

// Methods
import { _ } from "../../lib/index";

// Stylesheets
import "./CheckoutModal.scss";
import { useForm } from "../../shared/hooks/form-hook";

import { AUTHORIZE, CHECKOUT, MOBILE } from "../../lib/const";
import AuthorizePaymentModal from "../../components/authorize-payment/AuthorizePaymentModal";

const CheckoutModal = ({ clickCloseModal, user, selectedPlan, url }) => {
  console.log("CheckoutModal()");

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [isAuthorizeMode, setIsAuthorizeMode] = useState(false);

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        isValid: false,
        value: "",
      },
      password: {
        isValid: false,
        value: "",
      },
      passwordConfirm: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const navigate = useNavigate();

  const termsEl = useRef();
  const switchModalToAuthorize = () => {
    setFormData(
      {
        ...formState.inputs,
        username: undefined,
        password: { isValid: true, value: "" },
      },
      formState.inputs.email.isValid && formState.inputs.password.isValid
    );
    setIsAuthorizeMode((prevMode) => !prevMode);
  };
  useEffect(() => {
    setIsPaymentEnabled(agreedToTerms);
  }, [agreedToTerms]);

  const handleTermsChange = (e) => {
    let target = e.target;

    if (!target) return;

    setAgreedToTerms(target.checked);
  };

  const authorizePaymentWork = () => {
	switchModalToAuthorize()
    setActiveModal(AUTHORIZE);
  };

  return (
    <div>
      {!isAuthorizeMode && (
        <React.Fragment>
          <div className="checkout-modal">
            {/* Head Section */}
				<div className='head'>
					

					{/* <span className='message'>{_('Start today! We\'ll give you a free week, on us.')}</span> */}

				</div>

            {/* Body Section */}
            <div className="body">
              <span className="selected-plan">
                <span className="title">{_("Payment info")}</span>

						<hr />

                <span className="current-price">
                  {user &&
                    (!user.subscriptionData ||
                      Object.keys(user.subscriptionData).length === 0) && (
                      <span className="pitch">
                        {/*_('Get First Week Free, Then')*/}
                      </span>
                    )}
                  <span className="rate">{_(`${selectedPlan.price} ${selectedPlan.title}`)}</span>
                  <span className="details">
                    {_(
                      "Access unlimited effects and presets directly in Adobe Premiere Pro. Monthly updates with hundreds of assets added monthy."
                    )}
                  </span>
                </span>
              </span>

              <span className="terms-wrapper">
                <span className="checkbox-wrapper">
                  <input
                    onChange={handleTermsChange}
                    ref={termsEl}
                    type="checkbox"
                  />
                </span>
                {_("I have read and agree to ")}
                <a href="/terms-of-service">{_("terms and conditions")}</a>
              </span>
              <span class="selected-plan">
                <div className="row text-center">
                  <div className="col-md-12">
                    <PayPal isEnabled={isPaymentEnabled} subscriptionPlan={selectedPlan}/>
                  </div>
                   { <div className="col-md-12 mt-3">
                    <a href={selectedPlan.url} className="btn btn-primary btn-sm" type="button" disabled={!isPaymentEnabled}>
                    <i class="fa-solid fa-credit-card"></i> Debit or Credit Card
                    </a>
                  </div> } 
                </div>
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
	  {(isAuthorizeMode && <AuthorizePaymentModal selectedPlan={selectedPlan} clickCloseModal={clickCloseModal}></AuthorizePaymentModal>)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps)(CheckoutModal);

// Create Account Modal => Checkout Modal => Thank You Page

// Create Account > Payment Info > Thank You > My Account
