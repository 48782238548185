import { useState, useCallback, useRef, useEffect } from 'react';

export const useHttpClient = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [error, setError] = useState();

	const activeHttpRequests = useRef([]);

	const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
		setIsLoading(true);

		const httpAbortController = new AbortController();
		const id = setTimeout(() => httpAbortController.abort(), 8000)

		activeHttpRequests.current.push(httpAbortController);

		console.log('url: ', url);
		console.log('method: ', method);
		console.log('body: ', body);
		console.log('headers: ', headers);


		try {
			const response = await fetch(url, {
				method,
				body,
				headers,
				signal: httpAbortController.signal,
			});
			clearTimeout(id)

			console.log('response: ', response);
	
			const responseData = await response.json();

			console.log('responseData: ', responseData);

			// Clear abort controllers that belong to the request that just completed
			activeHttpRequests.current = activeHttpRequests.current.filter(requestController => requestController !== httpAbortController);
	
			if (!response.ok) {
				throw new Error(responseData.message);
			}

			setIsLoading(false);

			return responseData;

		} catch (error) {
			setError(error.message);
			setIsLoading(false);
			throw error;
		}
	}, []);

	const clearError = () => {
		setError(null);
	};

	useEffect(() => {
		return () => {
			activeHttpRequests.current.forEach(abortController => abortController.abort());
		};
	}, []);

	return {
		isLoading: isLoading,
		error: error,
		sendRequest: sendRequest,
		clearError: clearError,
	};
};
