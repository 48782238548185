// Libraries
import React from 'react';
import { connect } from 'react-redux';

// Components
import Footer from '../../components/footer/Footer';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './LifetimeMembershipPage.scss';

// VICTOR - get referral ID added to user state on initial creation
// Share this referral ID and when a user logs creates an account - Have a refferal ID?
// Connect the owner of that ID and grant them a point once the payment has suceeded in Paypal Component
// Ecah user has a unique user refferal link ID

const LifetimeMembershipPage = props => {
	let firstName = '';
	let referrals = 0;

	if (props.currentUser) {
		firstName = props.currentUser.firstName;
		referrals = 3;
	}

	return (
		<div className='lifetime-membership-page'>

			<div className='referrals-container'>
				<h2>{_('Refer some friends!')}</h2>
				<span>{_('Refer 10 friends to unlock free lifetime membership.')}</span>
				<span>{_('Each friend you refer gets 60 days free!')}</span>
			</div>


			<div className='rewards-container'>
				<span className='rewards-count'>{referrals}</span>
				<span className='rewards-text'>{_('Free months')}</span>
			</div>

			<div className='rewards-timeline'>

				<div className='timeline'></div>

				{/* One Month */}
				<div
					className='circle'
					id='orb-1'
				></div>
				<span id='oneMonth'>{_('+1 Month')}</span>
				<div id='oneFriend'>
					<span>{_('1st Friend')}</span>
					<span>{_('Get 1 free month')}</span>
				</div>

				{/* Two Month */}
				<div
					className='circle'
					id='orb-2'
				></div>
				<span id='twoMonth'>{_('+2 Month')}</span>
				<div id='twoFriend'>
					<span>{_('2nd Friend')}</span>
					<span>{_('Get 2 free months')}</span>
				</div>

				{/* Five Month */}
				<div
					className='circle'
					id='orb-3'
				></div>
				<span id='fiveMonth'>{_('+5 Month')}</span>
				<div id='fiveFriend'>
					<span>{_('5th Friend')}</span>
					<span>{_('Get 5 free months')}</span>
				</div>

				{/* One Year */}
				<div
					className='circle'
					id='orb-4'
				></div>
				<span id='oneYear'>{_('+1 Year')}</span>
				<div id='sevenFriend'>
					<span>{_('7th Friend')}</span>
					<span>{_('Get 12 free months')}</span>
				</div>

				{/* Lifetime */}
				<div id='heart'>&#10084;</div>
				<span id='lifetime'>{_('Lifetime')}</span>
				<div id='tenFriend'>
					<span>{_('10th Friend')}</span>
					<span>{_('Get a free lifetime subscription')}</span>
				</div>

			</div>

			<div className='personal-container'>
				<span id='referralLink'>{_('You personal referral link: 640studio.io/victor-1387')}</span>
				<span id='referralCount'>{_('Your Referrals:')} {referrals}</span>
			</div>

			<Footer />

		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(LifetimeMembershipPage);