// Note: Remember that these are all being read as slugs

// Collections
// > Presets
export const CRT = 'crt';
export const FILM_ESSENTIALS = 'film-essentials';
export const LUTS = 'luts';
export const MOTION_GRAPHICS = 'motion-graphics';
export const OVERLAYS = 'overlays';
export const POST_PRODUCTION_FILTERS = 'post-production-filters';
export const SOUND_DESIGN = 'sound-design';
export const TEXT_PRESETS = 'text-presets';
export const TEXTURES = 'textures';
export const TRANSITIONS = 'transitions';
// > Editing Software
export const AFTER_EFFECTS = 'after-effects';
export const ADOBE_LIGHTROOM = 'adobe-lightroom';
export const ADOBE_PHOTOSHOP = 'adobe-photoshop';
export const ADOBE_PREMIERE_PRO = 'adobe-premiere-pro';
export const DAVINCI_RESOLVE = 'davinci-resolve';
export const FINAL_CUT_PRO = 'final-cut-pro';

// Layouts
export const DESKTOP = 'DESKTOP';
export const MOBILE = 'MOBILE';
export const TABLET = 'TABLET';

// Modal Types
export const AUTHENTICATE = 'AUTHENTICATE';
export const CHECKOUT = 'CHECKOUT';
export const AUTHORIZE = 'AUTHORIZE';


// PayPal 
export const PAYPAL_CLIENT_ID =process.env.REACT_APP_PAYPAL_CLIENT_ID
export const PAYPAL_SECRET_KEY = process.env.REACT_APP_PAYPAL_SECRET // VV - hide this one..
export const PLAN_ID = process.env.REACT_APP_PAYPAL_PLAN_ID

export const FAQ = [
    {title:"What are the requirements for installation?",
     info:"Make sure to have over 30g of free space and be on the latest version of Adobe Premiere Pro.\n\nIf not, then there would be missing assets in the extension.",
     id:1},
     {title:"The extension loads forever when I try logging in.",
     info:'When signing in do not click the "Enter" button on your keyboard, instead click login with your cursor.\n\nPremiere thinks you\'re trying to render a clip when you sign in and click enter. This causes the extension to load forever.\n\nTry closing the extension window and re opening it through window > extension > 640 extension.\n\nJust for good measure, in the login screen you\'ll see a factory reset button, click that and it will reset the extension.\n\nYou\'ll have to re open it again.\n\nNow sign in, but this time click "login" with your mouse so Premiere doesn\'t try rendering any of your clips like when you clicked enter on the keyboard.\n\nThe extension settles in for a couple minutes upon first installation, but runs smoothly after you\'ve played around with it.',
     id:2},
     {title:"There’s assets missing in the extension.",
     info:"Make sure to have over 30g of free space and be on the latest version of Adobe Premiere Pro.\n\nIf not, then there would be missing assets in the extension.",
     id:3},
     {title:"Are there updates?",
     info:"Every month, we update the extension with new assets and improve bug fixes.\n\nThe extension is currently in beta version, but soon you will be able to activate specific assets directly from our website and instantly download them through the cloud.\n\nThis will greatly reduce the extension size and improve performance on your computer.",
     id:4},
     {title:"Assets don’t apply correctly (Mac users)",
     info:"First Go to settings > security preferences > accessibility.\n\nSecond enable the latest version of Adobe Premiere Pro within accessibility.\n\nThird go to settings > security preferences > automation Fourth enable the latest version of Adobe Premiere Pro accessibility.\n\nLast If Premiere asks for permission to perform actions within the app click OK",
     id:5},
     {title:"Account issues", info:"If you have problems cancelling your subscription, please send and email to: info640studio@gmail.com with the title CANCEL SUBSCRIPTION",
     id:6},
     {title:"Account issues", info:"If you paid for a subscription and it doesn't show up in the extension, please send an email to: info640studio@gmail.com with your payment receipt and email account"}
]

export const Subscriptions = [
    {
      title: "/ Monthly",
      price: "$14.95",
      period: "MONTHLY",
      features: [
        "Unlimited presets",
        "Monthly updates",
        "Up to 2 users",
        "Cancel anytime",
      ],
      popular: false,
      buttonText: "Get Started",
      backgroundColor: "#009244",
      description: "Our most affordable plan. $14.95/month. Cancel anytime.",
      popularText: "Popular",
      lastComment: "$14.95/ Month",
      highlighted: "Start using.",
      url:"https://buy.stripe.com/fZe28x5m355z4NOcMO"
    },
    {
      title: "/ Yearly",
      price: "$99",
      period: "YEARLY",
      features: [
        "Unlimited presets",
        "Monthly updates",
        "Up to 4 users",
        "Save $59 a year",
        "Cancel anytime",
      ],
      popular: false,
      buttonText: "Choose",
      description: "Have a team? Buy our Yearly plan and have up to 4 users.  Cancel anytime.", 
      lastComment: "Save $80",
      highlighted: "$99/year",
      url:"https://buy.stripe.com/5kA6oNg0HgOhfsscMM"

    },
      {
        title: "Lifetime",
        price: "$120",
        period: "LIFETIME",
        features: [
          "Unlimited presets",
          "Monthly updates",
          "Up to 6 users",
          "85% OFF",
          "$660 Value"
        ],
        popular: true,
        popularText: "Popular",

        buttonText: "Unlock Lifetime membership ",
        backgroundColor: "#009244",
        description: `Pay $120 once, enjoy 640 Extension for life.`,
        lastComment: `Limited for the next 100 users`,
        highlighted: "$120 One time.", 
        special: true,
        url:"https://buy.stripe.com/dR6cNb3dVapT3JK4gh"
      },
  ];

  function getRandomNumber(max) {
    
    return Math.floor(Math.random() * max);
  }

  //Need a function that creates a number between 50 and 100 store it on localstorage if it's not there and then return it
  //If it's there then return it
  //If it's not there then create it and return it
  function getNumber() {
    let number = localStorage.getItem("number");
    if (number) {
      return number;
    } else {
      let randomNumber = getRandomNumber(100);
      localStorage.setItem("number", randomNumber);
      return randomNumber;
    }
  }