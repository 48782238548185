// collectionReducer
export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';
export const SET_COLLECTION_CATEGORIES = 'SET_COLLECTION_CATEGORIES';
export const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA';
export const SET_COLLECTION_NAME = 'SET_COLLECTION_NAME';
export const SET_COLLECTION_SELECTED_KEYWORD = 'SET_COLLECTION_SELECTED_KEYWORD';

// errorReducer
export const ADD_ERROR = 'ADD_ERROR';
export const ADD_HTTP_ERROR = 'ADD_HTTP_ERROR';
export const ALLOW_ERROR = 'ALLOW_ERROR';
export const PREVENT_ERROR = 'PREVENT_ERROR';

// modalReducer
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const END_MODAL_LOADING = 'END_MODAL_LOADING';
export const OPEN_MODAL = 'OPEN_MODAL';
export const START_MODAL_LOADING = 'START_MODAL_LOADING';

// responsiveReducer
export const SET_RESPONSIVE = 'SET_RESPONSIVE';
export const TOGGLE_RESPONSIVE = 'TOGGLE_RESPONSIVE';

// userReducer
export const ACTIVATE_ASSET = 'ACTIVATE_ASSET';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const DEACTIVATE_ASSET = 'DEACTIVATE_ASSET';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const RESUBSCRIBE_USER = 'RESUBSCRIBE_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER';
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER';

export const SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE'
