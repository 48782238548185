// Libraries
import React from 'react';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './EditButton.scss';

const EditButton = () => (
	<button className='edit-button'>
		{_('EDIT')}
	</button>
);

export default EditButton;