// Libraries
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Card from "./Card";
// Methods
import { _ } from "../../lib";
import { Subscriptions } from "../../lib/const";
// Constants

// Stylesheets
import "./SalesFunnelBeta.scss";

import { AUTHENTICATE, CHECKOUT, MOBILE } from "../../lib/const";
import SubscriptionsComponent from "./SubscriptionsComponent";

const SalesFunnelBeta = ({ handleStartFreeClick, background, responsive , user}) => {

  
  const betaAssets = {
    // Demos - webm
    "asset-trio": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.webm`,
    "showcase-demo": `${process.env.REACT_APP_CDN_URL}/home-page/showcase-demo.webm`,
    "text-demo": `${process.env.REACT_APP_CDN_URL}/home-page/text-demo.webm`,
    "textures-demo": `${process.env.REACT_APP_CDN_URL}/home-page/textures-demo.webm`,
    "transitions-demo": `${process.env.REACT_APP_CDN_URL}/home-page/transitions-demo.webm`,
    // Demos - mp4
    "asset-trio-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.mp4`,
    "showcase-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/showcase-demo.mp4`,
    "text-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/text-demo.mp4`,
    "textures-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/textures-demo.mp4`,
    "transitions-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/transitions-demo.mp4`,

    //Demos new db - mp4
    "square-extension-newsite-mp4": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/square-extension-newsite.mp4`,
    "homepage-video": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/homepage-video.mp4`,
    //Demos new db - img icons
    "crt-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/crt-icon.png`,
    "film-essentials-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/film-essentials-icon.png`,
    "motion-graphics-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/motion-graphics-icon.png`,
    "overlays-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/overlays-icon.png`,
    "post-production-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/post-production-icon.png`,
    "sound-design-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/sound-design-icon.png`,
    "text-presets-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/text-presets-icon.png`,
    "transitions-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/transitions-icon.png`,
    "vhs-emulator-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/vhs-emulator-icon.png`,
    "textures-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/textures-icon.png`,
    "updates-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/updates-icon.png`,

    //Demos new db - png
    "list-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/list-icon.png`,

    // More Demos - mp4
    crt: `${process.env.REACT_APP_CDN_URL}/home-page/crt.mp4`,
    "text-presets": `${process.env.REACT_APP_CDN_URL}/home-page/text-presets.mp4`,
    textures: `${process.env.REACT_APP_CDN_URL}/home-page/textures.mp4`,
    transitions: `${process.env.REACT_APP_CDN_URL}/home-page/transitions.mp4`,
    // Images
    "640-premiere-logo": `${process.env.REACT_APP_2_CDN_URL}/assets/img/Logos_assets.png`,
    "activate-button": `${process.env.REACT_APP_CDN_URL}/home-page/activate-button.png`,
    edits: `${process.env.REACT_APP_CDN_URL}/home-page/edits.png`,
    "laptop-extension": `${process.env.REACT_APP_CDN_URL}/home-page/laptop-extension.png`,
    "mac-computer": `${process.env.REACT_APP_CDN_URL}/home-page/mac-computer.png`,
    overlays: `${process.env.REACT_APP_CDN_URL}/home-page/overlays.png`,
    texts: `${process.env.REACT_APP_CDN_URL}/home-page/texts.png`,
    transitions: `${process.env.REACT_APP_CDN_URL}/home-page/transitions.png`,
    updates: `${process.env.REACT_APP_CDN_URL}/home-page/updates.png`,
    "your-text-asset": `${process.env.REACT_APP_CDN_URL}/home-page/your-text-asset.png`,
  };
  const funnelAssets = {
    "sales-funnel": `${process.env.REACT_APP_CDN_URL}/home-page/sales-funnel.webm`,
    "your-text-asset": `${process.env.REACT_APP_CDN_URL}/home-page/your-text-asset.png`,
    "activate-button": `${process.env.REACT_APP_CDN_URL}/home-page/activate-button.png`,
    "mac-computer": `${process.env.REACT_APP_CDN_URL}/home-page/mac-computer.png`,
    "640-premiere-logo": `${process.env.REACT_APP_CDN_URL}/home-page/640-premiere-logo.png`,
    "asset-trio": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.webm`,

    "asset-trio-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.mp4`,
    "sales-funnel-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/sales-funnel.mp4`,
  };

  const isMobile = responsive === MOBILE;

  return (
    <div className="sales-funnel-beta">
      {/* Section One */}
      <div className="section-one" onClick={!isMobile && handleStartFreeClick}>
        {isMobile && (
          <div className="section-one-main-title">
            <h1 className="section-one-title">Unlimited Tools</h1>
            <h1 className="section-one-title-two">for editors</h1>
            <span>Access unlimited assets directly in Adobe Premiere Pro.</span>
          </div>
        )}

        {isMobile && (
          <div>
            <div className="row sec">
              <div className="col-md-3 col-sm-3 col">
                <ul
                  style={{
                    listStyle: `url(${betaAssets["list-icon"]})`,
                  }}
                >
                  <li>Test presets</li>
                  <li>Cine Titles</li>
                  <li>Textures</li>
                  <li>Overlays</li>
                  <li>CRT scans</li>
                  <li>Transitions</li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-3 col">
                <ul
                  style={{
                    listStyle: `url(${betaAssets["list-icon"]})`,
                  }}
                >
                  <li>VHS Emulator</li>
                  <li>Motion Graphics</li>
                  <li>Sound design</li>
                  <li>Diffusion filters</li>
                  <li>Film essentials</li>
                  <li>Updates</li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="video-wrapper">
          {isMobile ? (
            <>
              <video autoPlay loop muted playsInline>
                {/*If is not a mobile it renders the showcase video otherwise it renders the sales-funnel video*/}
                <source
                  src={betaAssets["square-extension-newsite-mp4"]}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <button
                className="get-started-btn"
                onClick={handleStartFreeClick}
              >
                Get Started
              </button>
            </>
          ) : (
            <>
              <h1>Unlimited creative assets</h1>
              <p>Color, sound design, motion graphics</p>
              <button
                className="get-started-btn"
                onClick={handleStartFreeClick}
              >
                <NavLink to="getStartedform">Get started</NavLink>
              </button>
              <video autoPlay loop muted playsInline>
                {/*If is not a mobile it renders the showcase video otherwise it renders the sales-funnel video*/}
                <source src={betaAssets["homepage-video"]} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </>
          )}
        </div>
      </div>

      {/* Section Two */}
      {isMobile ? (
        <>
          <div className="section-two">
            <h2>An extension for Adobe Premiere Pro.</h2>
            <p className="section-two-first-text">
              Access unlimited effects & presets directly in Adobe Premiere Pro.
              Our integration with Adobe delivers the anticipated synergy for
              editors.
            </p>
            <div className="section-two-second-text">
              <p>
                Simply activate your desired asset to seemlessly integrate
                within Adobe Premiere Pro.
              </p>
              <img
                alt="abobe-premiere-640-logos"
                src={betaAssets["640-premiere-logo"]}
              />
            </div>
          </div>
        </>
      ) : (
        <><div
            className="section-two"
          >
            <div className="content-wrapper">
              <h2>All in one place.</h2>
              <div className="get-started-items">
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`transitions-icon`]}")`,
                    }}
                  ></div>
                  <h3>Transitions</h3>
                  <p>
                    Scrub through thousands of transitions simply double click to
                    add your favorite.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`text-presets-icon`]}")`,
                    }}
                  ></div>
                  <h3>Text presets</h3>
                  <p>
                    Hundreds of presets to choose from, lower thirds, pan motions,
                    vintage text and cine titles.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`overlays-icon`]}")`,
                    }}
                  ></div>
                  <h3>Overlays</h3>
                  <p>
                    CRT scans, VHS, Film essentials, Film grains,
                    Burns and many more.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`text-presets-icon`]}")`,
                    }}
                  ></div>
                  <h3>Cine title</h3>
                  <p>
                    Movie inspired pre made titles with vintage controls. Fully
                    customizable with fonts included.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`motion-graphics-icon`]}")`,
                    }}
                  ></div>
                  <h3>Motion Graphics</h3>
                  <p>
                    Iphone pop ups, text messages, icons, subscribe buttons,
                    instagram geo tags are fully customizable
                    within the extension.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`post-production-icon`]}")`,
                    }}
                  ></div>
                  <h3>Post production filters</h3>
                  <p>
                    Forget to film it? do it in post. Diffusion filters, fish eye
                    lenses, 4K anamorphic lens flares etc.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`film-essentials-icon`]}")`,
                    }}
                  ></div>
                  <h3>Film essentials</h3>
                  <p>
                    Everything vintage such as motion film mattes, grains, burns,
                    leaks, scratches, dust, hairs, and many more.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`sound-design-icon`]}")`,
                    }}
                  ></div>
                  <h3>Sound design</h3>
                  <p>
                    Cinematic, Horror, Action, Foley, Explosions, Wooshes, Drones,
                    drops, bass, etc.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`crt-icon`]}")`,
                    }}
                  ></div>
                  <h3>CRT</h3>
                  <p>
                    Abstract overlays, fonts, numbers, symbols, text presets and
                    vintage controls.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`textures-icon`]}")`,
                    }}
                  ></div>
                  <h3>Textures</h3>
                  <p>
                    Tape, wrapper texture, paper texture, High quality
                    PNG with alpha.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`vhs-emulator-icon`]}")`,
                    }}
                  ></div>
                  <h3>VHS Emulation</h3>
                  <p>
                    Tape vhs sound effects, static, overlays, post
                    production add-ons.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${betaAssets[`updates-icon`]}")`,
                    }}
                  ></div>
                  <h3>Updates</h3>
                  <p>
                    Scrub through thousands of transitions simply double click to
                    add your favorite.
                  </p>
                </div>
              </div>
            </div>

            <div className="images-wrapper">
              <img
                alt="your-text-asset"
                className="your-text-asset"
                src={funnelAssets["your-text-asset"]} />
              <div className="activate-wrapper">
                <span>{_("Activate Text Preset")}</span>
                <img
                  alt="activate-button"
                  className="activate-button"
                  src={funnelAssets["activate-button"]} />
              </div>
            </div>
          </div>
          </>
      )}
     
      <SubscriptionsComponent marginTop="2em" ishandleClick={true} isMobile={true} user={user}></SubscriptionsComponent>
      
   
    </div>
  );
};

export default SalesFunnelBeta;
