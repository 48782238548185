// Libraries
import React from 'react';

// Components
import ActivateAsset from '../activate-asset/ActivateAsset';
import LutsDropdown from '../luts-dropdown/LutsDropdown';

// Stylesheets
import './LutsSelectedCard.scss';

const LutsSelectedCard = props => {
	console.log('props: ', props);

	const {
		description,
		imgSrc,
		name,
	} = props.data;

	return (
		<div className='luts-selected-card'>

			{/* Image */}
			<div className='image-container'>
				<img
					alt='luts'
					src={imgSrc}
				/>
			</div>

			{/* Title, Description, Actions Buttons */}
			<div className='content-container'>
				<span className='name'>{name}</span>
				<span className='description'>{description}</span>
				<div className='action-buttons'>
					<LutsDropdown />
					<ActivateAsset />
				</div>
			</div>

		</div>
	);
};

export default LutsSelectedCard;