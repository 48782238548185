// Libraries
import React from 'react';
import { connect } from 'react-redux';

// Components
import SoundCard from '../sound-card/SoundCard';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './SoundCardList.scss';

// VICTOR - add Loading Spinner, there's a lot going on, make this more efficient
// VICTOR - highlight last selected keyword, add way to get rid of it as well
// VICTOR - only allow 6 to render per page, infinite scrolling functionality

const SoundCardList = ({
	data,
	selectedKeyword,
}) => {

	const collectionData = data;

	let tableContent;

	if (collectionData && collectionData.length) {

		if (!selectedKeyword) {
			tableContent = collectionData.map((item, idx) => (
				<SoundCard
					filePath={item.file}
					imagePath={item.image}
					key={idx}
					keywords={item.keywords}
					name={item.name}
				/>
			));

		} else {
			let filteredCollectionData = collectionData.filter(item => item.keywords.includes(selectedKeyword));

			tableContent = filteredCollectionData.map((item, idx) => (
				<SoundCard
					filePath={item.file}
					imagePath={item.image}
					key={idx}
					keywords={item.keywords}
					name={item.name}
				/>
			));
		}

	}

	return (
		<div className='sound-card-list'>

				<table className='table'>
					<colgroup>
						<col span='1' style={{ width: '7.5%'}} />
						<col span='1' style={{ width: '7.5%'}} />
						<col span='1' style={{ width: '15%'}} />
						<col span='1' style={{ width: '7.5%'}} />
						<col span='1' style={{ width: '7.5%'}} />
						<col span='1' style={{ width: '15%'}} />
					</colgroup>

					<thead className='table-head'>
						<tr>
							<td>{_('Pack')}</td>
							<td></td>
							<td>{_('File Name')}</td>
							<td>{_('Time')}</td>
							<td>{_('Category')}</td>
							<td></td>
						</tr>
					</thead>

					<tbody className='table-body'>
						{tableContent}
					</tbody>

				</table>

		</div>
	);
};

const mapStateToProps = state => {
	return {
		selectedKeyword: state.collection.selectedKeyword,
	};
};

export default connect(mapStateToProps)(SoundCardList);