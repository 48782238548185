// Libraries
import React, { useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux Actions
import { setCollectionSelectedKeyword } from '../../redux/actions.js';

// Components
import LutsSelectedCard from '../luts-selected-card/LutsSelectedCard';

// Stylesheets
import './LutsSelected.scss';

// Data
const lutsSelectedData = [
	{
		imgSrc: 'https://dgx9rrgrsfte9.cloudfront.net/wp-content/uploads/2018/08/23131924/Camping-In-The-Desert-banner.jpg',
		name: 'Palm Springs',
		description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quis distinctio adipisci in maxime. Ab, est doloremque? Dolore consectetur molestias quae saepe modi consequuntur alias quo?'
	},
	{
		imgSrc: 'https://sfo2.digitaloceanspaces.com/cwblog/2019/05/21162117/Anza-Trend-Bndck-Mtn-backdrp-wide-e1558473880109.jpg',
		name: 'New Mexico',
		description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quis distinctio adipisci in maxime nobis libero sit, perferendis repellendus iste eligendi dolore ab earum molestiae atque, consequatur voluptatibus nesciunt quia! Excepturi dignissimos.'
	},
];

const LutsSelected = props => {
	return (
		<div className='luts-selected'>
			<div className='luts-selected-header'>
				{props.selectedKeyword} LUT's
			</div>

			<div className='luts-selected-wrapper'>
				{/* Map of Luts Selected Card */}

				{lutsSelectedData && lutsSelectedData.map(luts => (
					<LutsSelectedCard
						data={luts}
					/>

				))}

			</div>

		</div>
	);
};

const mapStateToProps = state => {
	return {
		selectedKeyword: state.collection.selectedKeyword,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCollectionSelectedKeyword,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LutsSelected);