// Libraries
import React from 'react';

// Components
import CollectionCard from '../collection-card/CollectionCard';

// Stylesheets
import './CollectionCardList.scss';

const CollectionCardList = props => {
	const {
		name,
		data,
	} = props;

	return (
		<div className='collection-card-list'>
			<div className='wrapper'>


				{data && data.length &&
					data.map((item, idx) => (
						<CollectionCard
							assetInfo={item}
							key={idx}
							name={name}
						/>
					))
				}

				
			</div>
		</div>
	);
};

export default CollectionCardList;