// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from "axios"
// Methods
import { _ } from '../../lib';

// Constants
import { MOBILE } from '../../lib/const';

// Stylesheets
import './ThankYou.scss';

const Instructions = ({
	responsive,
	user,
}) => {
	const navigate = useNavigate();

	// If user is not authenticated then take them to the home page
	if (!user.isAuthenticated) navigate('/');

	const thankYouAssets = {
		'640-banner': `${process.env.REACT_APP_2_CDN_URL}/assets/img/640-banner.png`,
		'640-checkmark': `${process.env.REACT_APP_2_CDN_URL}/assets/img/checkmark.png`,
		'640-logo-orange': `${process.env.REACT_APP_2_CDN_URL}/assets/img/640-logo.png`,
		'activation-toggle': `${process.env.REACT_APP_2_CDN_URL}/assets/img/activation-toggle.png`,
		'installer-window': `${process.env.REACT_APP_2_CDN_URL}/assets/img/installer-window.png`,
		'zxp-logo': `${process.env.REACT_APP_2_CDN_URL}/assets/img/zxp-logo.png`,
		'zxp-logo-window': `${process.env.REACT_APP_2_CDN_URL}/assets/img/zxp-logo-window.png`,
		'window-menu': `${process.env.REACT_APP_2_CDN_URL}/assets/img/window-menu.png`,
	};


	const handleExtensionClick = () => {
		let url = "https://s3.us-east-2.amazonaws.com/640studio.io/640_Studio_Extension.zxp"
		axios.get(url).then(res => res.blob()).then(file => {
			let tempUrl = URL.createObjectURL(file);
			const aTag = document.createElement("a");
			aTag.href = tempUrl;
			aTag.download = url.replace(/^.*[\\\/]/, '');
			document.body.appendChild(aTag);
			aTag.click();
			URL.revokeObjectURL(tempUrl);
			aTag.remove();
		}).catch(() => {
			alert("Failed to download file!");
		});
	};

	const handlePresetsClick = () => {
		console.log('handlePresetsClick'); // VV - finish this
	};

	const handleZXPClick = () => {
		window.open('https://aescripts.com/learn/zxp-installer/', '_blank');
	};

	// If screen display has not been determined, return
	if (!responsive) return;

	const isMobile = responsive === MOBILE;

	return (
		<div className={`thank-you-page${isMobile ? ' mobile' : ''}`}>
			<div className='header-section'>
				<div className='banner'>
					<img
						alt='640-banner'
						src={thankYouAssets['640-banner']}
					/>
				</div>

				{isMobile ?
					<React.Fragment>

						<div className='title poppins-heading'>
							{_('Good to go!')}
						</div>

						<div className='text'>
							<div className='confirm-account'>
								{_('Please confirm your account via email.')}
							</div>

							<div className='checkmark'>
								<img
									alt='640-checkmark'
									src={thankYouAssets['640-checkmark']}
								/>
							</div>

							<div className='desktop-visit'>
								{_('Visit us on your desktop to download our full extension and start creating!')}
							</div>
						</div>
					</React.Fragment>
					:
					<React.Fragment>
						<div className='title poppins-heading'>
							{_('Let\'s get started')}
						</div>

						<div className='text'>
							<div>{_('640 is always getting faster and easier to use.')}</div>

							<div>{_('You\'ll only need to install once, then you can simply login via Adobe Premiere.')}</div>

							<div>{_('Let\'s set up your extension.')}</div>
						</div>

						<div className='section-break'>
							<hr />
							<img
								alt='640-logo'
								src={thankYouAssets['640-logo-orange']}
							/>
							<hr />
						</div>
					</React.Fragment>
				}
			</div>

			{!isMobile &&
				<React.Fragment>
					<div className='main-section'>
						<div className='top-section'>
							{_('Before we start, make sure all of your Adobe programs are closed.')}
						</div>

						<div className='instructions'>
							<div className='instruction'>
								<div className='left-section'>
									<div className='image-wrapper'>
										<img
											alt='installer-window'
											src={thankYouAssets['installer-window']}
										/>
									</div>
								</div>

								<div className='right-section'>
									<div className='content-wrapper'>
										<div className='title poppins-heading'>
											<span className='title-sub'>{_('Download ZXP Installer')}</span>
											<span className='title-sub'>{_('& 640 Extension')}</span>
											<img
												alt='zxp-logo'
												src={thankYouAssets['zxp-logo']}
											/>
										</div>

										<div className='note'>
											{_('ZXP Installer allows our extension to be directly installed in Adobe Premiere Pro. This allows for easy future updates.')}
										</div>

										<div className='cta-buttons'>
											<div className='wrapper'>
												<button onClick={handleZXPClick}>
													{_('Download ZXP')}
												</button>

												<button /*onClick={handleExtensionClick}*/>
													<a href="https://s3.us-east-2.amazonaws.com/640studio.io/640_Studio_Extension_v2_0_5.zxp">Download Extension</a>
													
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='instruction'>
								<div className='left-section'>
									<div className='content-wrapper'>
										<div className='title poppins-heading'>
											{_('Installation')}
										</div>

										<div className='note'>
											{_('Drag the 640 Extension directly in ZXP installer or open from the file menu.')}
										</div>

										<div className='note'>{_('Installation may take a few minutes.')}</div>

										<div className='note'>{_('That\'s it!')}</div>
									</div>
								</div>

								<div className='right-section'>
									<div className='image-wrapper'>
										<img
											alt='zxp-logo-window'
											src={thankYouAssets['zxp-logo-window']}
										/>

										<div className='extension-label'>{_('640 Extension')}</div>
									</div>
								</div>
							</div>
						</div>

						<div className='launch-adobe-premiere'>
							<div className='title'>{_('Launch Adobe Premiere Pro')}</div>

							<div className='note'>
								{_('To open extension within Adobe Premiere Pro go to Window > Extensions > 640 Studio Extension')}
							</div>

							<div className='image-wrapper'>
								<img
									alt='window-menu'
									src={thankYouAssets['window-menu']}
								/>
							</div>
						</div>

						{/* VV - finish this */}
						<div className='activation-section'>
							<div className='title'>
								<span>{_('Activation')}</span>
								<img
									alt='activation-toggle'
									src={thankYouAssets['activation-toggle']}
								/>
							</div>

							<div className='note'>
								{_('Activate any presets you like and it will automatically be imported to the extension.')}
							</div>

							{/* VV - take user to their account page and wipe last page from history.. */}
							<div className='link' onClick={handlePresetsClick}>
								{_('Explore presets')}
							</div>
						</div>
						<div className='tutorial-section'>

							<div className='title'>
								<span>{_('640 Extension Installation Tutorial')}</span>
									
							</div>
							<div className='video-wrapper' >

							<iframe width="560" height="315" src="https://www.youtube.com/embed/Yr5yIEH6H9o?si=ouCLPMpZhi5TMa5u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							
							</div>
						</div>
					</div>
				</React.Fragment>
			}
		</div>
	)
};

const mapStateToProps = state => {
	return {
		responsive: state.responsive.responsive,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Instructions);
