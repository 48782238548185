// Libraries
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import AccountPage from '../../pages/account/AccountPage';
import AuthenticateModal from '../../pages/authenticate-modal/AuthenticateModal';
import CheckoutModal from '../../pages/checkout-modal/CheckoutModal';
import CollectionPage from '../../pages/collection/CollectionPage';
import ContactPage from '../../pages/contact/ContactPage';
import DeliveryPolicyPage from '../../pages/delivery-policy/DeliveryPolicyPage';
import HomePage from '../../pages/home/HomePage';
import LifetimeMembershipPage from '../../pages/lifetime-membership/LifetimeMembershipPage';
import PrivacyPolicyPage from '../../pages/privacy-policy/PrivacyPolicyPage';
import RefundPolicyPage from '../../pages/refund-policy/RefundPolicyPage';
import TermsServicePage from '../../pages/terms-service/TermsServicePage';
import TutorialsPage from '../../pages/tutorials/TutorialsPage';

// Stylesheets
import './PageContainer.scss';

// Redux

import { LUTS } from '../../lib/const';

const PageContainer = props => {
	const {
		collection,
	} = props;

	let isLuts = false;

	if (collection && collection.name && collection.name === LUTS) isLuts = true;

	return (
		<div className={`page-container${isLuts ? ' is-luts' : ''}`}>
				<Routes>

						<Route
							element={<HomePage />}
							exact
							path='/'
						/>

						<Route
							element={<AccountPage />}
							exact
							path='/account'
						/>

						{/* VICTOR - CheckoutModal should overlay whatever page we are on.. */}
						<Route
							element={<CheckoutModal />}
							exact
							path='/checkout'
						/>

						{/* VICTOR - Generic Collection Pages */}
						<Route
							element={<CollectionPage />}
							// exact
							path='/collection/:collectionName'
						/>

						<Route
							element={<ContactPage />}
							exact
							path='/contact'
						/>

						<Route
							element={<TutorialsPage />}
							exact
							path='/tutorials'
						/>

						<Route
							element={<LifetimeMembershipPage />}
							exact
							path='/lifetime-membership'
						/>

						<Route
							element={<AuthenticateModal />}
							exact
							path='/auth'
						/>

						<Route
							element={<PrivacyPolicyPage />}
							exact
							path='/privacy-policy'
						/>

						<Route
							element={<RefundPolicyPage />}
							exact
							path='/refund-policy'
						/>

						<Route
							element={<DeliveryPolicyPage />}
							exact
							path='/delivery-policy'
						/>

						<Route
							element={<TermsServicePage />}
							exact
							path='/terms-of-service'
						/>
				</Routes>
				
		</div>
	);
}

const mapStateToProps = state => {
	return {
		collection: state.collection,
		user: state.user,
	}
};

export default connect(mapStateToProps)(PageContainer);