// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import PreviewCard from '../preview-card/PreviewCard';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './SalesFunnel.scss';

const SalesFunnel = () => {

	// In the order they appear..
	const funnelAssets = {
		// SECTION 1
		'sales-funnel': `${process.env.REACT_APP_CDN_URL}/home-page/sales-funnel.webm`,
		'your-text-asset': `${process.env.REACT_APP_CDN_URL}/home-page/your-text-asset.png`,
		'activate-button': `${process.env.REACT_APP_CDN_URL}/home-page/activate-button.png`,

		// SECTION 2
		'mac-computer': `${process.env.REACT_APP_CDN_URL}/home-page/mac-computer.png`,
		'640-premiere-logo': `${process.env.REACT_APP_CDN_URL}/home-page/640-premiere-logo.png`,

		// SECTION 3
		'asset-trio': `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.webm`,
	};

	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/checkout');
	};

	return (
		<div className='sales-funnel'>

				{/* Section One */}
				<div className='section-one'>

					<div className='video-wrapper'>
						<video
							autoPlay
							loop
							muted
						>
							<source
								src={funnelAssets['sales-funnel']}
								type='video/webm'
							/>
							Your browser does not support the video tag.
						</video>
					</div>

					<div className='content-wrapper'>
						<span className='title'>
							{_('Now even faster editing.')}
						</span>

						<span className='description'>
							{_('Access all of your assets directly in Adobe Premiere Pro.')}
						</span>

						<span className='description'>
							{_('Our integration with Premiere delivers the anticipated synergy for editors.')}
						</span>

						<br />

						<span className='description'>
							{_('Simply activate your desired asset to seemlessly integrate within Adobe Premiere Pro.')}
						</span>
					</div>

					<div className='images-wrapper'>
						<img
							alt='your-text-asset'
							className='your-text-asset'
							src={funnelAssets['your-text-asset']}
						/>
						<div className='activate-wrapper'>
							<span>{_('Activate Text Preset')}</span>
							<img
								alt='activate-button'
								className='activate-button'
								src={funnelAssets['activate-button']}
							/>
						</div>
					</div>

				</div>

				{/* Section Two */}
				<div className='section-two'>
					
					<div className='image-wrapper'>
						<img
							alt='mac-computer'
							src={funnelAssets['mac-computer']}
						/>
					</div>

					<div className='content-wrapper'>

						<span className='title'>
							{_('All in one place.')}
						</span>

						<span className='description'>
							{_('Unlimited royalty-free assets at your fingertips.')}
						</span>

						<span className='description'>
							{_('Download extension exclusively for Above Premiere Pro users.')}
						</span>

						<img
							alt='640-premiere-logo'
							className='logos'
							src={funnelAssets['640-premiere-logo']}
						/>

					</div>

				</div>

				{/* Section Three */}
				<div className='section-three'>

					<div className='content-wrapper'>

						<span className='title'>
							{_('Constantly updating & evolving')}
						</span>

						<span className='description'>
							{_('We understand the importance of updating and curating tools, which is why thousands of only the best creative tools are being added monthly.')}
						</span>

					</div>

					<div className='call-to-action'>
						<div className='video-wrapper'>
							<video
								autoPlay
								loop
								muted
							>
								<source
									src={funnelAssets['asset-trio']}
									type='video/webm'
								/>
								Your browser does not support the video tag.
							</video>
						</div>

						<button onClick={handleClick}>
							{_('start free')}
						</button>

						<span>{_('7 day free trial')}</span>
					</div>

				</div>

		</div>
	);
};

// const mapStateToProps = state => {
// 	return {

// 	};
// };

// const mapDispatchToProps = dispatch => {
// 	return bindActionCreators({

// 	}, dispatch);
// };

export default SalesFunnel;

{/* <video
autoPlay
loop
muted
>
<source
	src={assetURL}
	type='video/mp4'
/> */}
{/* VICTOR */}
{/* Backup format in case the initial one fails */}
{/* <source src='.ogg' type='video/ogg'> */}
{/* Your browser does not support the video tag. */}
{/* </video> */}