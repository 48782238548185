// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux
import { setCollectionSelectedKeyword } from '../../redux/actions.js';

// Stylesheets
import './LutsCard.scss';

const LutsCard = ({
	filePath,
	imagePath,
	setCollectionSelectedKeyword,
	title,
}) => {

	// VICTOR - Create helper function for these 3 - 'ConstructAssetPaths'

	let fullImagePath;
	let fullFilePath;
	let modifiedKeywords;

	// Organize the keywords

	// Construct the full image and file paths
	if (filePath && imagePath) {
		fullImagePath = `${process.env.REACT_APP_CDN_URL}/collection-images/${imagePath}`;
		fullFilePath = `${process.env.REACT_APP_CDN_URL}/collection-assets/${filePath}`;
	}

	const handleClick = () => {
		// Set Selected Keyword

		if (!title) return;

		let keyword = title.toLowerCase();

		setCollectionSelectedKeyword(keyword);
	};

	return (
		<div className='luts-card' onClick={handleClick}>
			<span>{title}</span>
			<img alt={title} src={fullImagePath} />
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCollectionSelectedKeyword,
	}, dispatch);
};

export default connect(null, mapDispatchToProps)(LutsCard);