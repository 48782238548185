// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Methods
import { _ } from '../../lib/index';

// Stylesheets
import './Footer.scss';

const Footer = () => {
	
	return (

		<div className='footer'>

			<div className='footer-wrapper'>

				<div className='footer-link'>
					<Link to='/contact'>
						{_('Contact Us')}
					</Link>
				</div>

				<div className='footer-link'>
					<Link to='/privacy-policy'>
						{_('Privacy Policy')}
					</Link>
				</div>

				<div className='footer-link'>
					<Link to='/refund-policy'>
						{_('Refund Policy')}
					</Link>
				</div>


				<div className='footer-link'>
					<Link to='/terms-of-service'>
						{_('Terms of Service')}
					</Link>
				</div>

			</div>

		</div>
	);
};

export default Footer;