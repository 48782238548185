// Libraries
import React from 'react';

// Components
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './DeliveryPolicyPage.scss';

const DeliveryPolicyPage = () => {
	return (
		<div className='delivery-policy-page'>

			<PageHeader title={_('Delivery Policy')} />
			
			<div className='content'>
			</div>

			<Footer />

		</div>
	);
};

export default DeliveryPolicyPage;