// Libraries
import React from 'react';

// Components
import VideoCard from '../video-card/VideoCard';

// Stylesheets
import './VideoCards.scss';

const DUMMY_DATA = [
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
	{
		description: 'Video Tutorial',
		thumbnailSrc: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
		videoSrc: 'https://archive.org/embed/Inner_Sanctum_movie',
	},
];

const VideoCards = () => {
	return (
		<div className='video-cards'>
			{DUMMY_DATA.map((tutorial, idx) => (
				<VideoCard
					description={tutorial.description}
					key={idx}
					thumbnailSrc={tutorial.thumbnailSrc}
					videoSrc={tutorial.videoSrc}
				/>
			))}
		</div>
	);
};

export default VideoCards;