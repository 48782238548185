// Libraries
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import { PayPalButton } from "react-paypal-button-v2";

// Helper Methods
import { _, activatePayPalSubscription, googleTag } from "../../lib/index";

// Constants
import { PAYPAL_CLIENT_ID } from "../../lib/const";

// Stylesheet
import "./PayPal.scss";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPal = ({ isEnabled, user, subscriptionPlan }) => {
  const navigate = useNavigate();

  const paypalOnApprove = async (subscriptionData, detail) => {
    const userID = user.userId;
    const email = user.email;
    const subscriptionID = subscriptionData.subscriptionID;
    const successfulActivation = await activatePayPalSubscription(
      userID,
      subscriptionData,
      subscriptionID,
      email,
      subscriptionPlan.period
    );

    if (successfulActivation) {
      googleTag("purchase", user.email);

      navigate("/thank-you");
    } else {
      navigate("/"); // VV - replace this with a catch-all, something went wrong page
    }
  };

  
  const paypalOnError = (err) => {
    console.log("Error: ", err);
  };

  const paypalSubscribe = (data, actions) => {
    switch (subscriptionPlan.period) {
      case "MONTHLY":
        return actions.subscription.create({
          //plan_id: "P-80V8175578246871MMVE6APY", // VV - update this value accordingly
		      //plan_id: "P-80V8175578246871MMVE6APY" 
          plan_id: "P-1RR111444S120702RMYAFIYA", // VV - update this value accordingly
        });
      case "YEARLY":
        return actions.subscription.create({
			  plan_id: "P-73L80383HB069504NMYAFK4I", // VV - update this value accordingly			
          //plan_id: "P-32651285E7702012RMVE57EY", // VV - update this value accordingly
        });
      default:
        return actions.subscription.create({
          plan_id: "P-80V8175578246871MMVE6APY", // VV - update this value accordingly
        });
    }
  };

  return (
    <div className={`paypal-wrapper${isEnabled ? "" : " disabled"}`}>
      <React.Fragment>
		{subscriptionPlan.period !== "LIFETIME" && 
        <PayPalButton
          amount={_(`$${subscriptionPlan.price}`)}
          catchError={(err) => paypalOnError(err)}
          createSubscription={(data, details) => paypalSubscribe(data, details)}
          currency={_("USD")}
          onApprove={(data, details) => paypalOnApprove(data, details)}
          onCancel={(err) => paypalOnError(err)}
          onError={(err) => paypalOnError(err)}
          options={{
            clientId: PAYPAL_CLIENT_ID,
            vault: true,
          }}
          fundingSource="paypal"
          style={{
            color: "silver",
            label: "subscribe",
            layout: "vertical",
            shape: "pill",
          }}
        />}
		{subscriptionPlan.period === "LIFETIME" &&
		<PayPalButton
		  amount={_("120.00")}
		  catchError={(err) => paypalOnError(err)}
		  //createSubscription={(data, details) => paypalSubscribe(data, details)}
		  currency={_("USD")}
		  
		  onApprove={(data, details) => paypalOnApprove(data, details)}
		  onCancel={(err) => paypalOnError(err)}
		  onError={(err) => paypalOnError(err)}
		  options={{
			clientId: PAYPAL_CLIENT_ID,
			vault: true,
		  }}
		
		  fundingSource="paypal"
		  style={{
			color: "silver",
			label: "subscribe",
			layout: "vertical",
			shape: "pill",
		  }}
		/>}
		
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PayPal);
