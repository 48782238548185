import {
	ADD_ERROR,
	ADD_HTTP_ERROR,
	ALLOW_ERROR,
	PREVENT_ERROR,
} from './types.js';

const INITIAL_STATE = {
	allowError: true,
	error: null,
	httpError: null,
};

const errorReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_ERROR:
			return {
				...state,
				error: action.payload,
			};

		case ADD_HTTP_ERROR:
			return {
				...state,
				httpError: action.payload,
			};

		case ALLOW_ERROR:
			return {
				...state,
				allowError: true,
			};
		
		case PREVENT_ERROR:
			return {
				...state,
				allowError: false,
			};

		default:
			return state;
	}

};

export default errorReducer;
