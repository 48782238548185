// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import LutsHome from '../../components/luts-home/LutsHome';
import LutsSelected from '../../components/luts-selected/LutsSelected';

// Stylesheets
import './Luts.scss';

// Luts
// - LutsHome
// - LutsSelected

const Luts = ({
	data,
	handleScroll,
	name,
	selectedKeyword,
}) => {

	const collectionData = data;

	let lutsContent;

	if (collectionData && collectionData.length) {
	
		// Show default if no selected keyword
		if (!selectedKeyword) {
			// Show LutsHome Component
			
			lutsContent = (
				<LutsHome
					data={collectionData}
					name={name}
				/>
			);

		} else {
			// Otherwise show LutsSelected Component
			// let filteredCollectionData = collectionData.filter(item => item.keywords.includes(props.selectedKeyword));

			// lutsContent = filteredCollectionData.map((item, idx) => (
			lutsContent = (
				<LutsSelected
					// luts={props.selectedKeyword}
				/>
			);
			// ));
		}
	}

	return (
		<div
			className='luts'
			onScroll={() => console.log('Scroll!')}
		>
			{lutsContent}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		selectedKeyword: state.collection.selectedKeyword,
	};
};

export default connect(mapStateToProps)(Luts);