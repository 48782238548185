// Libraries
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import CollectionCardList from '../../components/collection-card-list/CollectionCardList';
import CollectionNavigation from '../../components/collection-navigation/CollectionNavigation';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Luts from '../luts/Luts';
import SoundDesign from '../sound-design/SoundDesign';

// Methods - Redux, Custom Hooks, Utils
import {
	setCollectionCategories,
	setCollectionData,
	setCollectionName,
	setCollectionSelectedKeyword,
} from '../../redux/actions.js';
import { useHttpClient } from '../../shared/hooks/http-hook';
import {
	prepareCollectionCategories,
	unslugify,
} from '../../lib';
import {
	LUTS,
	SOUND_DESIGN,
} from '../../lib/const';

// Stylesheets
import './CollectionPage.scss';

const CollectionPage = ({
	collection,
	setCollectionData,
	setCollectionName,
}) => {

	// Unslugify collection name to use as title
	let collectionName = useParams().collectionName;
	// Construct page title from gathered slug

	let title;

	if (collection && collection.name) {
		title = unslugify(collection.name);
	} else if (collectionName) {
		title = unslugify(collectionName);
		setCollectionName(collectionName);
	}

	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const [loadedData, setLoadedData] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const [displayedData, setDisplayedData] = useState(null);
	const [collectionCategories, setCollectionCategories] = useState(null);

	// New colleciton - reset data
	// useEffect(() => {
	// 	setLoadedData(null);
	// 	setDisplayedData(null);
	// }, [collectionName]);

	// 'componentDidMount'
	useEffect(() => {
		console.log('componentDidMount()');
	}, []);

	// User has navigated to a new collection page
	useEffect(() => {
		console.log('User has navigated to a new collection page..');

		// Update redux state with the current collection

		// VICTOR - set collection Data to null
		// VICTOR - update
		
	}, [collection]);

	// Fetch the collection data
	useEffect(() => {
		// Reset everything before we fetch
		setLoadedData(null);
		setDisplayedData(null);

		const fetchCollectionData = async () => {
			try {
				if (!collection.name) throw Error;

				console.log(process.env.REACT_APP_BACKEND_URL);

				const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/collection/${collection.name}`);

				setCollectionData(responseData.collection[0].data);

				// VICTOR - add this to redux
				// loadedData contains everything that was fetched
				setLoadedData(responseData.collection[0].data);

				// VICTOR - add this to redux
				// displayedData is a subset of loadedData and is referenced by the pageNumber
				setDisplayedData(responseData.collection[0].data.slice(0, 8));

			} catch (err) {}
		}

		fetchCollectionData();
	}, [sendRequest, collection.name]);

	useEffect(() => {
		let collectionCategories = [];

		if (loadedData) {

			for (let i = 0; i < loadedData.length; i++) {
				collectionCategories.push(
					...loadedData[i].keywords
				);
			}

			collectionCategories = prepareCollectionCategories(collectionCategories);

			setCollectionCategories(collectionCategories);
		}

	}, [loadedData]);

	const handleScroll = (e) => {
		let target = e.target;

		// User has reached the bottom of the table height
		if (target.scrollTop === (target.scrollHeight - target.offsetHeight)) {

			// Increase pageNumber
			setPageNumber(pageNumber + 1);
			// Show next batch of data
			setDisplayedData(loadedData.slice(0, 8 * pageNumber));

			// VICTOR - make this more efficient, append to what we've already got!
			// Organize displayedData ad pageNumbers on page load
		}
	};
	
	return (
		<React.Fragment>

			{/* VICTOR - add loading spinner when a request is being made */}

			<div className='collection-page'>

				{displayedData &&
					<React.Fragment>
						{collection.name === SOUND_DESIGN ?
							<SoundDesign
								data={displayedData}
								handleScroll={handleScroll}
								name={collection.name}
							/>
							:
							collection.name === LUTS ?
							<Luts
								data={loadedData}
								handleScroll={handleScroll}
								name={collection.name}
							/>
							:
							<CollectionCardList
								data={displayedData}
								name={collection.name}
							/>
						}
					</React.Fragment>
				}

				{!displayedData &&
					<LoadingSpinner />
				}

				<CollectionNavigation
					categories={collectionCategories}
					collectionName={collection.name}
				/>

			</div>

		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		collection: state.collection,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCollectionCategories,
		setCollectionData,
		setCollectionName,
		setCollectionSelectedKeyword,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPage);
