import { useCallback, useReducer } from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';
const SET_DATA = 'SET_DATA';

const formReducer = (state, action) => {
	switch (action.type) {
		case INPUT_CHANGE:
			let formIsValid = true;
			for (const inputId in state.inputs) {
				if (!state.inputs[inputId]) {
					continue;
				}
				if (inputId === action.inputId) {
					formIsValid = formIsValid && action.isValid;
				} else {
					formIsValid = formIsValid && state.inputs[inputId].isValid;
				}
			}

			return {
				...state,
				inputs: {
					...state.inputs,
					[action.inputId]: {
						value: action.value,
						isValid: action.isValid
					}
				},
				isValid: formIsValid,
			};

		case SET_DATA:
			return {
				inputs: action.inputs,
				isValid: action.formIsValid
			};

		default:
			return state;
	}
};

export const useForm = (initialInputs, initialFormValidity) => {
	const [formState, dispatch] = useReducer(formReducer, {
		inputs: initialInputs,
		isValid: initialFormValidity,
	});

	const inputHandler = useCallback((id, value, isValid) => {
		dispatch({
			inputId: id,
			isValid: isValid,
			type: INPUT_CHANGE,
			value: value,
		});
	}, [dispatch]);

	const setFormData = useCallback((inputData, formValidity) => {
		dispatch({
			formIsValid: formValidity,
			inputs: inputData,
			type: SET_DATA,
		})
	}, []);

	return [formState, inputHandler, setFormData];
};
