// Libraries
import React, { useState, useRef } from 'react';

// Components
import LutsCard from '../luts-card/LutsCard';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './LutsHome.scss';

const LutsHome = props => {
	let lutsData = props.data;

	return (
		<div className='luts-home'>
			<div className='luts-header'>{_('Choose a look')}</div>
			<div className='luts-wrapper'>

				{lutsData && lutsData.map((luts, idx) => (
					<LutsCard
						filePath={luts.file}
						imagePath={luts.image}
						key={idx}
						title={luts.name}
						// VICTOR - translate the title..
					/>
				))}

				{/* LutsCards 9x9 */}

			</div>
		</div>
	);
};

export default LutsHome;