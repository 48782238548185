// Libraries
import React from 'react';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './StartFree.scss';

const StartFree = ({
	handleStartFreeClick,
}) => {
	return (
		<div className='start-free' onClick={handleStartFreeClick}>
			<button>
				{_('Start Now')}
			</button>
		</div>
	);
};

export default StartFree;
