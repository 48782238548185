// Libraries
import React, { useRef } from 'react';

// Components
import AssetName from '../asset-name/AssetName';
import ActivateAsset from '../activate-asset/ActivateAsset';

import {
	TRANSITIONS,
} from '../../lib/const';

// Stylesheets
import './CollectionCard.scss';

const CollectionCard = props => {
	const {
		assetInfo,
		name,
	} = props;

	const activeGif = useRef(null);

	let assetName, imagePath, filePath, fullImagePath, fullFilePath;

	if (assetInfo) {
		assetName = assetInfo.name;
		imagePath =  assetInfo.image;
		filePath =  assetInfo.file;

		// Construct the full path names for assets
		fullImagePath = `${process.env.REACT_APP_CDN_URL}/collection-images/${imagePath}`;
		fullFilePath = `${process.env.REACT_APP_CDN_URL}/collection-assets/${filePath}`;
	}

	return (
		<div className='collection-card'>
			
			{name === TRANSITIONS ?
				<video
					loop
					onMouseOut={() => activeGif.current.pause()}
					onMouseOver={() => activeGif.current.play()}
					ref={activeGif}
					src={fullImagePath}
				>
				</video>
			:
				<img
					alt={assetName}
					src={fullImagePath}
				/>
			}

			<div className='asset-details'>
				<AssetName name={assetName} />
				<ActivateAsset
					assetName={assetName}
				/>
			</div>
		</div>
	);

};

export default CollectionCard;

// VICTOR - not sure if we will need these
// const handleClick = e => {

// 	// if (!isPlaying) {
// 	// 	currentAudio.current.play();
// 	// } else {
// 	// 	currentAudio.current.pause();
// 	// }
// 	// setIsPlaying(!isPlaying);
// };

// const updateTrackTime = () => {
// 	// let currTime = Math.floor(currentAudio.current.currentTime).toString();
// 	// let duration = Math.floor(currentAudio.current.duration).toString();

// 	// if (currTime === duration) {
// 	// 	setCurrentTime('00:00');
// 	// 	setIsPlaying(false);
// 	// 	return;
// 	// }

// 	// setCurrentTime(formatSecondsAsTime(currTime));

// 	// if (isNaN(duration)) {
// 	// 	setTotalDuration('00:00');
// 	// } else {
// 	// 	setTotalDuration(formatSecondsAsTime(duration));
// 	// }
// };

// const currentAudio = useRef();
// const [isPlaying, setIsPlaying] = useState(false);
// const [currentTime, setCurrentTime] = useState('');
// const [totalDuration, setTotalDuration] = useState('');

// const formatSecondsAsTime = (secs, format) => {
// 	let hr  = Math.floor(secs / 3600);
// 	let min = Math.floor((secs - (hr * 3600)) / 60);
// 	let sec = Math.floor(secs - (hr * 3600) -  (min * 60));

// 	if (min < 10) {
// 		min = '0' + min;
// 	}

// 	if (sec < 10) {
// 		sec  = '0' + sec;
// 	}

// 	return min + ':' + sec;
// };

// {fullImagePath && fullFilePath &&
// 	<React.Fragment>
// 		<div className='img-container'>

// 			<i className={`fa-5x fa fa-${isPlaying ? 'pause' : 'play'}-circle-o`}></i>

// 			<div>
// 				<span className='current-time'>{currentTime} </span>-
// 				<span className='total-duration'> {totalDuration}</span>
// 			</div>

// 			<img alt='asset' src={fullImageUrl} />
// 		</div>

// 		<audio
// 			controls
// 			controlsList='nodownload'
// 			onTimeUpdate={updateTrackTime}
// 			ref={currentAudio}
// 		>
// 			<source src={fullFileUrl} type='audio/wav' />
// 			Your browser does not support the audio element.
// 		</audio>
// 	</React.Fragment>
// }

