// Libraries
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Components
import Beta from "./components/beta-launch/Beta";
import CheckoutModal from "./pages/checkout-modal/CheckoutModal";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import Header from "./components/header/Header";
import Instructions from "./components/beta-launch/Instructions";
import MobileFunnel from "./pages/mobile-funnel/MobileFunnel";
import MyAccount from "./components/beta-launch/MyAccount";
import PageContainer from "./components/page-container/PageContainer";
import Sidebar from "./components/sidebar/SideBar";
import Subheader from "./components/subheader/Subheader";
import ThankYou from "./components/beta-launch/ThankYou";

// Footer Components
import ContactPage from "./pages/contact/ContactPage";
import DeliveryPolicyPage from "./pages/delivery-policy/DeliveryPolicyPage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";
import RefundPolicyPage from "./pages/refund-policy/RefundPolicyPage";
import TermsServicePage from "./pages/terms-service/TermsServicePage";

import Modal from "react-modal";

// Redux
import { setCurrentUser, setResponsive } from "./redux/actions.js";

// Methods
import { _, authenticateUser } from "./lib";

// Constants
import { DESKTOP, MOBILE, TABLET } from "../src/lib/const";

// Stylesheets
import "./styles/App.scss";
import ResetPassword from "./components/beta-launch/ResetPassword";
import Login from "./components/beta-launch/Login";
import GetStartedForm from "./components/beta-launch/GetStartedForm";
import PaymentDetails from "./components/beta-launch/PaymentDetails.js";
import UserView from "./components/beta-launch/UserView.js";
import SubscriptionsComponent from "./components/beta-launch/SubscriptionsComponent.js";

Modal.setAppElement("#root");

let isBeta = true;

const App = (props) => {
  console.log("App()");

  const [modal, setModal] = useState(false);

  const [token, setToken] = useState(false);

  const [userId, setUserId] = useState(false);

  const navigate = useNavigate();

  // 'componentDidMount'
  useEffect(() => {
    const setCurrentUser = async () => {
      const storedData = JSON.parse(localStorage.getItem("userData"));

      if (storedData && storedData.token && storedData.userId) {
        const { token, userId } = storedData;

        let currentUser = await authenticateUser(token, userId);

        console.log("currentUser: ", currentUser);
        if (currentUser) {
          props.setCurrentUser(currentUser);

          // Now that the user has been authenticated and set in to redux state, take them to my-account page
          navigate("/my-account");
        } else {
          navigate("/");
        }
      }
    };

    const setLayout = () => {
      // Mobile layout if no URL string is provided
      if (window.innerWidth < 768) {
        // Set responsive to 'MOBILE'
        props.setResponsive(MOBILE);
      } else if (window.innerWidth >= 1024) {
        // Set responsive to 'DESKTOP'
        props.setResponsive(DESKTOP);
      } else {
        // Set responsive to 'TABLET'
        props.setResponsive(TABLET);
      }
    };

    setLayout();
    setCurrentUser();
  }, []);

  const login = useCallback((userId, token) => {
    console.log("login()");

    console.log("userId: ", userId);
    console.log("token: ", token);

    setToken(token);
    setUserId(userId);
    const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60);

    console.log("tokenExpirationDate: ", tokenExpirationDate);

    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: userId,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    console.log("logout()");

    setToken(null);
    setUserId(null);
    localStorage.clear();
  }, []);

  // useEffect(() => {
  // 	console.log('Inside here!');
  // 	// Check our local stoarge for a token
  // 	const storedData = JSON.parse(localStorage.getItem('userData'));
  // 	console.log('storedData: ', storedData);
  // 	if (storedData && storedData.token) {
  // 		login(storedData.userId, storedData.token);
  // 		// Login user with redux
  // 		authenticateUser()
  // 	}

  // }, [login]);

  const toggleModal = () => {
    // setIsModalOpen(!isModalOpen);
  };

  let content;

  if (isBeta) {
    content = (
      <Routes>
        <Route element={<Beta />} exact path="/" />
        <Route element={<Login />} path="/login" />
        <Route element={<PaymentDetails/>} path="/paymentDetails" />

        <Route element={<MyAccount />} exact path="/my-account" />

        <Route element={<Instructions />} exact path="/instructions" />
        <Route element={<ResetPassword />} exact path="/reset/:id" />
        <Route element={<UserView />} exact path="/user" />
        <Route element={<SubscriptionsComponent />} exact path="/subscriptions" />
        <Route element={<CheckoutModal />} exact path="/checkout" />
        {/* Thank You Page contains pixel */}
        <Route element={<ThankYou />} exact path="/thank-you" />

        {/* Footer Routes */}
        <Route element={<ContactPage />} exact path="/contact" />
        <Route element={<PrivacyPolicyPage />} exact path="/privacy-policy" />
        <Route element={<RefundPolicyPage />} exact path="/refund-policy" />
        <Route element={<DeliveryPolicyPage />} exact path="/delivery-policy" />
        <Route element={<TermsServicePage />} exact path="/terms-of-service" />

        {/* Catch All Error Route */}
      </Routes>
    );
  } else if (props.responsive === MOBILE) {
    // VICTOR - fix this responsive.responsive, I don't like it, responsive.display or responsive.layout

    content = <MobileFunnel />;
  } else if (props.responsive === TABLET) {
    content = "Hello Tablet";
  } else if (props.responsive === DESKTOP) {
    content = (
      <div className={`app ${!props.collection.name ? "home" : ""}`}>
        {/* <ErrorBoundary> */}

        <Header />

        {props.collection.name && <Subheader />}

        {/* VICTOR - review Sidebar component */}
        <Sidebar />

        <PageContainer />

        {/* </ErrorBoundary> */}

        {/* {props.modal.isModalOpen &&
						<Modal className='modal'>
							<div>HELLLLLLLO</div>
						</Modal>
					} */}
      </div>
    );
  } else {
    // Something went wrong..
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    collection: state.collection,
    modal: state.modal,
    responsive: state.responsive.responsive,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCurrentUser,
      setResponsive,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
