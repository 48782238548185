
import { SUBSCRIPTION_TYPE } from './types.js';
// Define the initial state of the subscription reducer
const initialState = {
    subscription: null,
};

// Define the subscription reducer function
function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_TYPE:
      return {
        ...state,
        subscription: action.payload,
      };
   
    default:
      return state;
  }
}

// Export the subscription reducer function
export default subscriptionReducer;
