import React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = props => {
	if (props.href) {
		return (
			<a
				className={`button button--${props.size || 'default'} ${props.inverse &&
				'button--inverse'} ${props.danger && 'button--danger'}`}
				href={props.href}
			>
				{props.children}
			</a>
		);
	}

	if (props.to) {
		return (
			<Link
				className={`button button--${props.size || 'default'} ${props.inverse &&
				'button--inverse'} ${props.danger && 'button--danger'}`}
				exact={props.exact}
				to={props.to}
			>
				{props.children}
			</Link>
		);
	}

	return (
		<button
			className={`btn button`}
			disabled={props.disabled}
			onClick={props.onClick}
			type={props.type}
		>
			{props.children}
		</button>
	);
};

export default Button;