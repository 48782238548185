export const addItemToActives = (activeAssets, itemData) => {
	let { assetName, collectionName } = itemData;

	const isActive = activeAssets[collectionName].find(
		item => item === assetName
	);

	if (isActive) {
		// Already inside activeAssets, do nothing and exit out
		return;
	}

	return {
		...activeAssets,
		[collectionName]: [
			...activeAssets[collectionName],
			assetName,
		],
	};

};

export const removeItemFromActives = (activeAssets, itemData) => {

	let { assetName, collectionName } = itemData;

	const isActive = activeAssets[collectionName].find(
		item => item === assetName
	);

	if (!isActive) {
		// Not in activeAssets to begin with, do nothing and exit out
		return;
	}

	return {
		...activeAssets,
		[collectionName]: activeAssets[collectionName].filter(item => item !== assetName),
	};
	
};

export const addItemToFavorites = (favoriteAssets, itemData) => {

};

export const removeItemFromFavorites = (favoriteAssets, itemData) => {

};
