import {
	CLEAR_COLLECTION,
	SET_COLLECTION_CATEGORIES,
	SET_COLLECTION_DATA,
	SET_COLLECTION_NAME,
	SET_COLLECTION_SELECTED_KEYWORD,
} from './types.js';

// This is the 'user' state
const INITIAL_STATE = {
	// currentCategories: null,
	// currentCollection: null,
	// selectedKeyword: null,


	categories: [],
	data: [],
	name: null,
	selectedKeyword: null

};

const collectionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CLEAR_COLLECTION:
			return {
				...state,
				categories: [],
				data: [],
				name: null,
				selectedKeyword: null,
			};

		case SET_COLLECTION_CATEGORIES:
			return {
				...state,
				categories: action.payload,
			};

		case SET_COLLECTION_DATA:
			return {
				...state,
				data: action.payload,
			};

		case SET_COLLECTION_NAME:
			return {
				...state,
				name: action.payload,
			};

		case SET_COLLECTION_SELECTED_KEYWORD:
			return {
				...state,
				selectedKeyword: action.payload,
			};

		default:
			return state;
	}
};

export default collectionReducer;
