// Libraries
import React from 'react';

// Stylesheets
import './PreviewCard.scss';

const PreviewCard = props => {

	return (
		<div className='preview-card'>
			<img
				alt=''
				src={props.data.image}
			/>
		</div>
	);
};

export default PreviewCard;