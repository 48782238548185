// Libraries
import React from 'react';

// Component
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import BetaHeader from '../../components/beta-launch/BetaHeader' ;

// Methods
import { _ } from '../../lib';

// Stylesheets
import './RefundPolicyPage.scss';
import {
	MOBILE,
} from '../../lib/const';
const RefundPolicyPage = (responsive) => {
	const isMobile = responsive === MOBILE;

	return (
		<div
			className={`my-account${isMobile ? ' mobile': ''}`}>
				<BetaHeader
							
							isTabs={true}
					/>
		<div className='refund-policy-page'>

			
			<div className='content'>
			<h1>Refund Policy</h1>
			Refund Policy
You will only be entitled to receive a refund for any fees paid to us if you cancel your subscription within 14 calendar days from the date of its purchase and/or renewal (“Refund Period”) and you did not download any Assets during such Refund Period. You hereby understand and agree that you will not be entitled to any refunds if: (i) you do not ask the Company for refund during the Refund Period; or (ii) you download any Asset from the Site during the Refund Period.

Additional non-returnable items:
- Gift cards
- Downloadable software products
- Some health and personal care items

To complete your return, we require a receipt or proof of purchase.
Please do not send your purchase back to the manufacturer.

There are certain situations where only partial refunds are granted (if applicable)
- Book with obvious signs of use
- CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened
- Any item not in its original condition, is damaged or missing parts for reasons not due to our error
- Any item that is returned more than 30 days after delivery

Refunds (if applicable)
Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.

Late or missing refunds (if applicable)
If you haven’t received a refund yet, first check your bank account again.
Then contact your credit card company, it may take some time before your refund is officially posted.
Next contact your bank. There is often some processing time before a refund is posted.
If you’ve done all of this and you still have not received your refund yet, please contact us at 640studio@gmail.com.

Sale items (if applicable)
Only regular priced items may be refunded, unfortunately sale items cannot be refunded.
			</div>

			<Footer />

		</div>
		</div>
	);
};

export default RefundPolicyPage;