// Libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';


// Components

// Methods
// import { postError } from '../../redux/error/actions';
import { _ } from '../../lib';

// Stylesheets
import './ErrorBoundary.scss';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
		};
	}

	reloadPage = () => {
		console.log('reloadPage()');
		window.location.reload();
	};

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });

		// Error Information
		let errorInfo = _('No error information provided');
		if (error) {
			errorInfo = error;
		}

		// Error Location
		let errorLocation = _('No error location provided');
		if (info && info.componentStack) {
			errorLocation = info.componentStack;
		}

		// Post Error
		let ROOT_URL = localStorage.getItem('port') || '';

		if (ROOT_URL) {
			// this.props.postError(ROOT_URL, errorInfo, errorLocation);
		}

	}

	render() {
		if (this.state.hasError) {
			return (
				<div className='error-boundary'>
					<div className='content'>
						<h5>
							{_('A UI Error has Occurred')}
						</h5>
		
						<br />
		
						<button
							className='btn'
							onClick={this.reloadPage}
							title={_('Reload Page')}
						>
							{_('RELOAD PAGE')}
						</button>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = state => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		// postError,
	}, dispatch)
};

ErrorBoundary = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

export default ErrorBoundary;

// export default withRouter(ErrorBoundary);