import React, { useState } from "react";
import './Faq-List.scss';


const ListItem = ({ item }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <li onClick={() => setShowInfo(!showInfo)}>
      <h3>{item.title}</h3>
      {showInfo && <p >{item.info}</p>}
    </li>
  )  
};

const FaqList = ({ items }) => {
  return (
    
    <ul>
      {items.map(item => (
    <div>
        <ListItem key={item.id} item={item} />
        <hr className="line"></hr>
    </div>
       
      ))}
    </ul>
  );
};

export default FaqList;