import {
	ACTIVATE_ASSET,
	ADD_FAVORITE,
	SET_CURRENT_USER,
	DEACTIVATE_ASSET,
	LOGOUT_USER,
	REMOVE_FAVORITE,
	RESUBSCRIBE_USER,
	SUBSCRIBE_USER,
	UNSUBSCRIBE_USER,
} from './types.js';

import {
	addItemToActives,
	addItemToFavorites,
	removeItemFromActives,
	removeItemFromFavorites,
} from './user.utils';

import {
	// Presets
	CRT,
	FILM_ESSENTIALS,
	LUTS,
	MOTION_GRAPHICS,
	OVERLAYS,
	POST_PRODUCTION_FILTERS,
	SOUND_DESIGN,
	TEXT_PRESETS,
	TEXTURES,
	TRANSITIONS,
	// Editing Software
	// AFTER_EFFECTS,
	// ADOBE_LIGHTROOM,
	// ADOBE_PHOTOSHOP,
	// ADOBE_PREMIERE_PRO,
	// DAVINCI_RESOLVE,
	// FINAL_CUT_PRO,
} from '../lib/const';

let initialAssets = {
	[CRT]: [],
	[FILM_ESSENTIALS]: [],
	[LUTS]: [],
	[MOTION_GRAPHICS]: [],
	[OVERLAYS]: [],
	[POST_PRODUCTION_FILTERS]: [],
	[SOUND_DESIGN]: [],
	[TEXT_PRESETS]: [],
	[TEXTURES]: [],
	[TRANSITIONS]: [],
	// [AFTER_EFFECTS]: [],
	// [ADOBE_LIGHTROOM]: [],
	// [ADOBE_PHOTOSHOP]: [],
	// [ADOBE_PREMIERE_PRO]: [],
	// [DAVINCI_RESOLVE]: [],
	// [FINAL_CUT_PRO]: [],
};

let testingAssets = {
	[CRT]: [],
	[FILM_ESSENTIALS]: [],
	[LUTS]: [],
	[MOTION_GRAPHICS]: [],
	[OVERLAYS]: [],
	[POST_PRODUCTION_FILTERS]: [],
	[SOUND_DESIGN]: [],
	[TEXT_PRESETS]: [],
	[TEXTURES]: [],
	[TRANSITIONS]: [
		'01 Easy Fly In',
		'02 Easy Fly In - Quick',
		'03 Easy Fly In - Right',
	],
	// [AFTER_EFFECTS]: [],
	// [ADOBE_LIGHTROOM]: [],
	// [ADOBE_PHOTOSHOP]: [],
	// [ADOBE_PREMIERE_PRO]: [],
	// [DAVINCI_RESOLVE]: [],
	// [FINAL_CUT_PRO]: [],
};

const INITIAL_STATE = {
	// VV - we will add these later 11/22
	// activeAssets: initialAssets,
	// activeAssets: testingAssets,
	// downloads
	// favoriteAssets: initialAssets,

	createdAt: null,
	email: null,
	isAuthenticated: false,
	isSubscribed: false,
	password: null,
	subscriptionData: {},
	subscriptionID: '',
	token: null,
	userId: null,
	username: null,
};

// VICTOR - add an image upload later..

// NOTE: These are passed in by the user..

// These are set on our end..

// VICTOR - make sure that the back end is notified of any change in user state

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTIVATE_ASSET:
			return {
				...state,
				activeAssets: addItemToActives(state.activeAssets, action.payload),
			};

		case ADD_FAVORITE:
			return {
				...state,
				favoriteAssets: addItemToFavorites(state.favoriteAssets, action.payload),
			};

		case DEACTIVATE_ASSET:
			return {
				...state,
				activeAssets: removeItemFromActives(state.activeAssets, action.payload)
			};

		case LOGOUT_USER:
			return {
				...state,
				...INITIAL_STATE,
			};

		case REMOVE_FAVORITE:
			return {
				...state,
				favoriteAssets: removeItemFromFavorites(state.favoriteAssets, action.payload),
			};

		case SET_CURRENT_USER:
			console.log('SET_CURRENT_USER');

			console.log('action.payload: ', action.payload);

			if (action.payload && action.payload.token) {
				return {
					...state,
					isAuthenticated: true,
					...action.payload,
					// email: action.payload.email,
					// token: action.payload.token,
					// userId: action.payload.id,
				};
			}

			return {
				...INITIAL_STATE,
			}

		case SUBSCRIBE_USER:
			console.log('SUBSCRIBE_USER');
			console.log('action.payload: ', action.payload);

			return {
				...state,
				isSubscribed: true,
				subscriptionData: {
					...action.payload.subscriptionData,
				},
				subscriptionID: action.payload.subscriptionID,
			}

		case UNSUBSCRIBE_USER:
			console.log('UNSUBSCRIBE_USER');
			// VV - test unsubscribing from my-account page..
			return {
				...state,
				isSubscribed: false,
				// VV - is it necessary to update these?
				subscriptionData: {
					...action.payload,
				},
				subscriptionID: action.payload.subscriptionID,
			};

		default:
			return state;
	}
};

export default userReducer;
