// Libraries
import React from 'react';

// Stylesheets
import './AssetName.scss';

const AssetName = ({ name }) => {
	return (
		<div className='asset-name'>
			<span title={name}>
				{name}
			</span>
		</div>
	);
};

export default AssetName;