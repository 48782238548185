import React from 'react';
import './Card.scss'; // Asegúrate de crear un archivo CSS para tus estilos
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { subscriptionType } from '../../redux/actions.js';

function Card({ title, price, features, period, popular, buttonText, backgroundColor, description, popularText, handleSubmit, props,lastComment, highlighted, special}) {
    const featureList = features.map((feature, index) => (
        <li key={index}>{feature}</li>
    ));

    const handleClick = () => {
        //Set period to redux
        // setSubscriptionType(period);
        subscriptionType(period);

    }


    return (
        <div className={`card ${popular ? 'popular' : ''}  ${special ? 'card-special' : ''} col-md-3`} >
            {popular && <div className="card-badge" style={{ backgroundColor }}>{popularText}</div>}
            <div className="card-content">
                <h2 className='card-title'>{price} {title}</h2>
                <p className='card-description'>{description}</p>
                <span className='highlighted-text'>{highlighted}</span>

                <ul className='card-list'>
                    {featureList}
                </ul>
                <p className="card-lastComment">{lastComment}</p>
                <button onClick={handleSubmit}>{buttonText}</button>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
	return {
		subscription: state.subscription,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		subscriptionType,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);

