// Libraries
import React from 'react';

// Components
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import VideoCards from '../../components/video-cards/VideoCards';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './TutorialsPage.scss';

const TutorialsPage = () => (
	<div className='tutorials-page'>

		<PageHeader title={_('Tutorials')} />

		<div className='content'>
			<VideoCards />
		</div>

		<Footer />

	</div>
);

export default TutorialsPage;