// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Methods
import { _ } from '../../lib/index';

// Redux Actions
import {
	activateAsset,
	clearCollection,
	deactivateAsset,
	openModal,
} from '../../redux/actions.js';

// Stylesheets
import './ActivateAsset.scss';

// VICTOR - if user clicks and is not logged in then refer them to log/in sign up page

const ActivateAsset = props => {

	const [isActive, setIsActive] = useState(false);

	const inputElement = useRef();

	const navigate = useNavigate();

	// Check if user has any active assets within this collection..
	useEffect(() => {
		if (props.user && props.collection && props.user.activeAssets[props.collection.name]) {

			if (props.user.activeAssets[props.collection.name].includes(props.assetName)) {
				setIsActive(true);
			}

		}
	}, [props.user]);

	const handleChange = async () => {

		// Check if user is logged in..
		// if (!props.user || !props.user.userId) {
		// 	// User is not logged in, present them with checkout modal
		// 	props.openModal();
		// 	props.clearCollection();
		// 	// navigate('/checkout');
		// 	navigate('/checkout');
		// 	return;
		// }

		// User is in fact logged in, continue..

		// Update isActive to reflect the user's selection
		setIsActive(inputElement.current.checked);

		let assetData = {
			assetName: props.assetName,
			collectionName: props.collection.name,
		};

		if (inputElement.current.checked) {
			// Add to activated assets
			await props.activateAsset(assetData);

		} else {
			// Remove from activated assets
			await props.deactivateAsset(assetData);
		}

		return;
	};

	return (
		<div className='activate-asset'>
			<span className='title'>{_('Activate')}</span>
			<label className='switch'>
				<input
					checked={isActive}
					onChange={handleChange}
					ref={inputElement}
					type='checkbox'
				/>
				<span className='slider round'></span>
			</label>
		</div>
	);

};

const mapStateToProps = state => {
	return {
		collection: state.collection,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		activateAsset,
		clearCollection,
		deactivateAsset,
		openModal,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAsset);