// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components

// Methods - Redux, Custom Hooks, Utils, Constants
import {
	clearCollection,
	setCollectionName,
	setCollectionCategories,
	setCollectionSelectedKeyword,
} from '../../redux/actions.js';

import {
	// ADOBE_LIGHTROOM,
	// ADOBE_PHOTOSHOP,
	// ADOBE_PREMIERE_PRO,
	// AFTER_EFFECTS,
	CRT,
	// DAVINCI_RESOLVE,
	FILM_ESSENTIALS,
	// FINAL_CUT_PRO,
	LUTS,
	MOTION_GRAPHICS,
	OVERLAYS,
	POST_PRODUCTION_FILTERS,
	SOUND_DESIGN,
	TEXT_PRESETS,
	TEXTURES,
	TRANSITIONS,
} from '../../lib/const';

import { _ } from '../../lib';

// Stylesheets
import './SideBar.scss';

// Note
// Overlays
// > CRT
// > 

// VICTOR - Clean up component and scss files

const Sidebar = ({
	clearCollection,
	collection,
	setCollectionName,
	user,
}) => {

	let username;

	if (user) {
		username = user.username;
	}

	const handleSidebarClick = e => {

		let target = e.target;

		// If nothing special was clicked, break out
		if (!target) return;

		let collectionName = target.getAttribute('data-collection');

		// If empty space was clicked, break out
		if (!collectionName) return;

		// Compare this collectionName to current collection name
		if (collection.name !== collectionName) {
			// User has navigated to a different collection page

			// Clear any pre-exisiting collection information
			clearCollection();

			// Set collection name
			setCollectionName(collectionName);
		}
	};

	console.log('user: ', user);

	return (
		<div
			className='sidebar'
			onClick={(e) => handleSidebarClick(e)}
		>

			{/* User Account Information Section */}
			<div className='user-container'>

				{user && user.isAuthenticated &&
					<React.Fragment>
						
						{/* <div className='sidebar-user-profile-img'>
							O
						</div> */}

						<div className='user-details'>
							<span className='sidebar-user-name'>
								<i className='fa-solid fa-circle'></i>
								{(user && user.username) ? user.username : ''}
							</span>

							<span className='sidebar-user-actives'>
								{_('Active Assets: ')}
								{user.activeAssets.length ? user.activeAssets.length : 0}
							</span>
						</div>

					</React.Fragment>
				}

				<div className='sidebar-user-links-container'>
					<ul className='sidebar-user-links'>

						{user && user.isAuthenticated &&
							<li>
								<Link to='/account'>
									{_('My Account')}
								</Link>
							</li>
						}

						<li>
							{user && user.isAuthenticated ?
								<Link
									to='/'
								>
									{_('Log Out')}
								</Link>
								:
								<Link to='/auth'>
									{_('Log In')}
								</Link>
							}
						</li>
					</ul>
				</div>

			</div>

			{/* Warning Section */}
			<div className='sidebar-section warning'>
				<span>
					<i className='fa-solid fa-triangle-exclamation'></i>
					{/* VICTOR - change warning section */}
					{_('This extension is only ')}
					<br />
					{_('available for Adobe Premiere Pro')}
				</span>
			</div>

			{/* VICTOR - if any of these are selected then reset the selected keyword */}

			{/* Presets Section */}
			<div className='sidebar-section'>

				<h2 className='sidebar-section-heading'>{_('Presets')}</h2>

				<ul className='sidebar-section-list'>
{/* VICTOR - edit the data attribute naming? */}
					<li>
						<Link
							className={collection.name === CRT ? 'active' : ''}
							data-collection={CRT}
							data-keyword='crt'
							to='/collection/crt'
						>
							{_('CRT')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === FILM_ESSENTIALS ? 'active' : ''}
							data-collection={FILM_ESSENTIALS}
							data-keyword='film-essentials'
							to='/collection/film-essentials'
						>
							{_('Film Essentials')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === LUTS ? 'active' : ''}
							data-collection={LUTS}
							to='/collection/luts'
						>
							{_('LUTS')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === MOTION_GRAPHICS ? 'active' : ''}
							data-collection={MOTION_GRAPHICS}
							to='/collection/motion-graphics'
						>
							{_('Motion Graphics')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === OVERLAYS ? 'active' : ''}
							data-collection={OVERLAYS}
							to='/collection/overlays'
						>
							{_('Overlays')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === POST_PRODUCTION_FILTERS ? 'active' : ''}
							data-collection={POST_PRODUCTION_FILTERS}
							to='/collection/post-production-filters'
						>
							{_('Post Production Filters')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === SOUND_DESIGN ? 'active' : ''}
							data-collection={SOUND_DESIGN}
							to='/collection/sound-design'
						>
							{_('Sound Design')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === TEXT_PRESETS ? 'active' : ''}
							data-collection={TEXT_PRESETS}
							to='/collection/text-presets'
						>
							{_('Text Presets')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === TEXTURES ? 'active' : ''}
							data-collection={TEXTURES}
							data-keyword='textures'
							to='/collection/overlays'
						>
							{_('Textures')}
						</Link>
					</li>

					<li>
						<Link
							className={collection.name === TRANSITIONS ? 'active' : ''}
							data-collection={TRANSITIONS}
							to='/collection/transitions'
						>
							{_('Transitions')}
						</Link>
					</li>

				</ul>

			</div>

			{/* Editing Software Section */}
			<div className='sidebar-section'>

				<h2 className='sidebar-section-heading'>{_('Editing Software')}</h2>

				{/* Warning Section */}
				<div className='sidebar-section warning'>
					<span>
						{_('This extension is only available for Adobe Premiere Pro. We are working on adding the following softwares soon.')}
					</span>
				</div>

				<h3 className='sidebar-section-subheading'>{_('Coming Soon')}</h3>

				<ul className='sidebar-section-list'>

					{/* VICTOR - Make Component: SideBarLink.tsx */}

					{/* <li>
						<Link
							className={collection.name === AFTER_EFFECTS ? 'active' : ''}
							data-collection={AFTER_EFFECTS}
							to='/collection/after-effects'
						>
							{_('After Effects')}
						</Link>
					</li> */}

					<li>
						{_('Adobe Lightroom')}

						{/* <Link
							className={collection.name === ADOBE_LIGHTROOM ? 'active' : ''}
							data-collection={ADOBE_LIGHTROOM}
							to='/collection/adobe-lightroom'
						>
							{_('Adobe Lightroom')}
						</Link> */}
					</li>

					<li>
						{_('Adobe Photoshop')}

						{/* <Link
							className={collection.name === ADOBE_PHOTOSHOP ? 'active' : ''}
							data-collection={ADOBE_PHOTOSHOP}
							to='/collection/adobe-photoshop'
						>
						</Link> */}
					</li>

					{/* <li>
						<Link
							className={collection.name === ADOBE_PREMIERE_PRO ? 'active' : ''}
							data-collection={ADOBE_PREMIERE_PRO}
							to='/collection/adobe-premiere-pro'
						>
							{_('Adobe Premiere Pro')}
						</Link>
					</li> */}

					<li>
						{_('DaVinci Resolve')}

						{/* <Link
							className={collection.name === DAVINCI_RESOLVE ? 'active' : ''}
							data-collection={DAVINCI_RESOLVE}
							to='/collection/davinci-resolve'
						>
							{_('DaVinci Resolve')}
						</Link> */}
					</li>

					<li>
						{_('Final Cut Pro')}

						{/* <Link
							className={collection.name === FINAL_CUT_PRO ? 'active' : ''}
							data-collection={FINAL_CUT_PRO}
							to='/collection/final-cut-pro'
						>
							{_('Final Cut Pro')}
						</Link> */}
					</li>

				</ul>

			</div>

		</div>
	);
};

const mapStateToProps = state => {
	return {
		collection: state.collection,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		clearCollection,
		setCollectionName,
		setCollectionCategories,
		setCollectionSelectedKeyword,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);