// Libraries
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	Link,
	useNavigate,
} from 'react-router-dom';

// Components
import BetaHeader from './BetaHeader';
import FaqList from '../faq-list/Faq-List';

// Live Components
import CheckoutModal from '../../pages/checkout-modal/CheckoutModal';

// Redux Actions
import {
	logoutUser,
	subscriptionType
} from '../../redux/actions.js';

// Methods
import {
	_,
	cancelPayPalSubscription,
	cancelAuthorizeSubscription,
	getCurrentUserLocalStorage,
	removeCurrentUserLocalStorage,
	reactivatePayPalSubscription
} from '../../lib';

// Constants
import {
	CHECKOUT,
	MOBILE,
	FAQ
} from '../../lib/const';

// Stylesheets
import './MyAccount.scss';
import Footer from '../footer/Footer';

const MyAccount = ({
	
	logoutUser,
	subscriptionType,
	responsive,
	user,
}) => {
	console.log('MyAccount()');

	const navigate = useNavigate();

	useEffect(() => {
		console.log('componentDidMount');
				let currentUser = getCurrentUserLocalStorage();
			if (!currentUser) return;
	
			console.log('currentUser: ', currentUser);
			// Authenticate user..
			// Login the user here..
			
	}, []);

	// If user is not authenticated then take them to the home page
	if (!user.isAuthenticated) navigate('/');
	
	const [activeModal, setActiveModal] = useState(null);

	const [showOverlay, setShowOverlay] = useState(false);

	const handleCloseModal = () => {
		console.log('handleCloseModal()');
		setActiveModal(null);
		setShowOverlay(false);
	};

	const handleLogout = () => {
		// Log out the user
		logoutUser();

		removeCurrentUserLocalStorage();

		// Navigate the user to home page
		navigate('/');
	};

	const handlePasswordReset = () => {
		console.log('handlePasswordReset()');
		// Shoot off e-mail instructions to change password
		// The same way that we'd handle a forgotten password - except we will use the user email that we're already got on hand
		// VV - Figure this one out.. get API set up to handle this..

		// They get a unique link - and they can then reset the password there..
	};

	const handleSubscriptionClick = async() => {
		// Three Possible Situations
		// 1. User is currently subscribed and now wants to unsubscribe
		// 2. User is not currently subscribed, has been subscribed previously and now wants to re-activate
		// 3. User is not currently subscribed, has never been subscribed and now wants to subscribe

		if (user && user.isSubscribed) {
			// // Confirm user wants to cancel their subscription
			// const cancellationMessage = _(
			// 	'You are about to cancel your active subscription. Click \'OK\' if you\'d like to continue'
			// );

			// const continueCancellation = window.confirm(cancellationMessage); // // VV - replace this with an on-screen modal

			// // User doesn't want to cancel, exit out
			// if (!continueCancellation) return;
	
			// // User does in fact want to cancel
			// const userID = user.userId;
			// const email = user.email
			// const subscriptionData = user.subscriptionData;
			// const subscriptionID = user.subscriptionID;
			// let successfulCancellation = false
			// if(user.platform === 'AuthorizationNet'){
			// 	successfulCancellation = await cancelAuthorizeSubscription(subscriptionID, email);

			// }else{
			// 	successfulCancellation = await cancelPayPalSubscription(userID, subscriptionData, subscriptionID, email);

			// }

			// if (successfulCancellation) {
			// 	alert('You have successfully cancelled your subscription.') // VV - replace this with an on-screen modal
			// 	window.location.reload(false);


			// } else {
			// 	alert('There was a problem cancelling your subscription.')
			// 	//navigate('/'); // VV - replace this with a catch-all, something went wrong page
			// }
		} else if (user.subscriptionData && Object.keys(user.subscriptionData).length) {
			navigate('/subscriptions')
		}else{
			navigate('/subscriptions')
		}
	};

	// If screen display has not been determined, return
	if (!responsive) return;

	let staticPage = {
		overflow: 'auto',
	};

	const isMobile = responsive === MOBILE;

	console.log('user: ', user);

	return (
		<div
			className={`my-account${isMobile ? ' mobile': ''}`}
			
		>
			<div className={`${showOverlay ? 'obscure-' : ''}wrapper`}>
				<BetaHeader
					handleLogout={handleLogout}
					user={user}
				/>

				<div className='info-section'>
					<div className='info-content'>
						<div className='intro'>
							<div className='main-text'>{_('My Account')}</div>

							{user && user.isSubscribed &&
								<div className='access-download'>
									<span onClick={() => navigate('/instructions')}>
										{_('View Download Instructions')}
									</span>
								</div>
							}

							<div className='sub-text'>{_('View and edit your personal information below')}</div>
						</div>

						<div className='main'>
							<hr />

							<div className='input-wrapper'>
								<div>
									{_('Username: ')}
									<span>{user.username}</span>
								</div>
							</div>

							<div className='input-wrapper'>
								<div>
									{_('Email: ')}
									<span>{user.email}</span>
								</div>
							</div>

{/* VV - hiding this one for now, will come back to it first week of launch 12/10/22 */}
							{/* <div className='input-wrapper'>
								<div className='password-reset'>
									<button onClick={handlePasswordReset}>
										{_('Reset Password')}
									</button>
								</div>
							</div> */}
							<hr />
						</div>
					</div>

					<div className='cancel-content'>
						{user.isSubscribed ?
							user.platform === 'stripe' ?
							<div className='cancel-subscription'>
								<a href="https://billing.stripe.com/p/login/eVabLh6rnfEM0i4fYY" target="_blank" rel="noreferrer"><button  className='stripe-button btn btn-success'
								>Stripe Account Management</button></a>
							</div>:
							<div className='cancel-subscription'>
								
							</div>
							:
							<div className='cancel-subscription'>
								<button onClick={handleSubscriptionClick}>
									{_('Subscribe')}
								</button>
							</div>
						}
						
					</div>
					<div  className='cancel-content'>
						<h1>FAQ</h1>
						<FaqList items={FAQ}></FaqList>

					</div>
					<div  className='cancel-content'>
						<h2>Still have an issue? We're here to help.</h2>
						<span>Email us at info640studio@gmail.com</span>
					</div>
				</div>
				

				
				
			</div>
			
			{/* VV - new code.. */}
			{/* Only Show Checkout Modal If User Account Authenticated */}
			{(showOverlay && activeModal === CHECKOUT && user.isAuthenticated) &&
				<CheckoutModal clickCloseModal={handleCloseModal} />
			}
			<div>
			<div className='footer fixed-bottom'>
					<div className='footer-wrapper'>

						<div className='footer-link'>
							<Link to='/contact'>
								{_('Contact Us')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/privacy-policy'>
								{_('Privacy Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/refund-policy'>
								{_('Refund Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/delivery-policy'>
								{_('Delivery Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/terms-of-service'>
								{_('Terms of Service')}
							</Link>
						</div>

					</div>
				</div>

			</div>

		</div>
		
	);
};

const mapStateToProps = state => {
	return {
		responsive: state.responsive.responsive,
		user: state.user,
		subscription: state.subscription,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		logoutUser,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
