// Libaries
import React, { Component } from 'react';

// Stylesheets
import './AssetCard.scss';

class AssetCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			audioName: 'Horror - Riser',
			isPlaying: false,
		};
	}

	toggleIsPlaying = () => {
		this.setState({
			isPlaying: !this.state.isPlaying
		});
	}

	handleDownloadClick = () => {
	}

	render() {
		const { audioName, isPlaying } = this.state;

		return (
			<React.Fragment>
				<div className='asset-card'>

					<div
						className='card-wrapper'
						onClick={this.toggleIsPlaying}
					>

						<div className='card-img'>

							<div className='video-thumbnail'>

								{!isPlaying && 
									<i className='fa fa-play-circle-o' aria-hidden='true'></i>
								}

								{isPlaying && 
									<i className='fa fa-pause-circle-o' aria-hidden='true'></i>
								}
								
							</div>

						</div>

					</div>

					<div className='audio-info'>

						<span className='audio-name'>{audioName}</span>

						{/* VICTOR - Add ActivateAsset here.. */}
					</div>

				</div>

			</React.Fragment>
		);
	}
}


export default AssetCard;