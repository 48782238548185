// Libraries
import React from 'react';

// Components
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import BetaHeader from '../../components/beta-launch/BetaHeader' ;

// Methods
import { _ } from '../../lib';

// Stylesheets
import './ContactPage.scss';
import {
	MOBILE,
} from '../../lib/const';

const ContactPage = (
	
	responsive
) => {

	const isMobile = responsive === MOBILE;

	const handleSubmit = e => {
		e.preventDefault();

		// VICTOR - let the button be disabled unless user has something valid in email and something in comment section

		let target = e.target;

		if (!target) return;

		let name, email, phone, comment;

		// VICTOR - check for email and comment, they are required

		name = target.elements['name'].value;
		email = target.elements['email'].value;
		phone = target.elements['phone'].value;
		comment = target.elements['comment'].value;

		console.log(name, email, phone, comment);
		alert('This form is not working yet. But you can send an email to 640studio@gmail.com.')

		// Send this form data to the server

		// Alert user with a proper message

		// Clear form input fields

	};

	return (
		
		<div
			className={`my-account${isMobile ? ' mobile': ''}`}>
				<BetaHeader
							
							isTabs={true}
					/>

		<div className='contact-page'>
		

			{/* VICTOR - check these elsewhere, that the title has translation. */}

			<div className='content'>
				<h1>Contact</h1>
				<span>This form is not working yet. But you can send an email to 640studio@gmail.com.</span>

				{/* VICTOR - get this to send to the correct email endpoint, send server side and handle there */}
				<form action='' onSubmit={handleSubmit}>
					<input
						name='name'
						placeholder={_('Name')}
						type='text'
					/>

					<input
						name='email'
						placeholder={_('Email *')}
						required
						type='email'
					/>

					<input
						name='phone'
						placeholder={_('Phone number')}
						type='text'
					/>

					<textarea
						id=''
						name='comment'
						placeholder={_('Comment *')}
						required
					></textarea>

					<div>
						<button>{_('Send')}</button>

						<span>{_('* fields are required')}</span>
					</div>

				</form>

			</div>

			<Footer />
		</div>
		</div>
		
	);
};

export default ContactPage;