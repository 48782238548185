import axios from "axios";
import { Buffer } from "buffer";

export const sendAuthorizeRequest = async (user, info, selectedPlan) => {
    const {email, userId} = user;
    const {firstName, lastName, cardNumberHashed, expirationDateHashed, cardCodeHashed} = info;
 
    try{
        const body = {
            unit: selectedPlan.period,
            cardNumberHashed: Buffer.from(cardNumberHashed.value).toString('base64'),
            expirationDateHashed: Buffer.from(expirationDateHashed.value).toString('base64'),
            cardCodeHashed: Buffer.from(cardCodeHashed.value).toString('base64'),
            userId: userId,
            email: email,
            firstName: firstName.value,
            lastName: lastName.value,
            subscriptionType: selectedPlan.period,
            }
            if(selectedPlan.period === "LIFETIME"){
                return axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/subscribe/authorize/forlife`,body)
            }else{
                return axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/subscribe/authorize`,body)

            }

    }catch(e){
        throw new Error("Verify your data.")
    }
    
}


