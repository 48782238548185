// Libraries
import React from 'react';

// Stylesheets
import './PageHeader.scss';

const PageHeader = ({ title }) => (
	<div className='page-header'>
		<h1>{title}</h1>
	</div>
);

export default PageHeader;