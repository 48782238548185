
// Libraries
import React, {  } from 'react';
import { connect } from 'react-redux';
import {
	Link,
	useNavigate,
	useParams
} from 'react-router-dom';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';


import Button from '../../shared/components/FormElements/Button';

// Redux Actions
import {
} from '../../redux/actions.js';

// Methods
import {
	_,
	
} from '../../lib';

// Constants
import {
	MOBILE,
} from '../../lib/const';

// Stylesheets
import './MyAccount.scss';
import Input from '../../shared/components/FormElements/Input';

import {

	VALIDATOR_MINLENGTH
} from '../../shared/util/validators';
const ResetPassword = ({
	responsive,
	
}) => {
    const isMobile = responsive === MOBILE;

	let navigate = useNavigate()

	let { id } = useParams();

	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const [formState, inputHandler] = useForm({

		password: {
			isValid: false,
			value: '',
		},
		passwordConfirm: {
			isValid: false,
			value: '',
		},
	}, false);

    // If screen display has not been determined, return
	if (!responsive) return;

	let staticPage = {
		overflow: 'unset',
	};

	const handleReset =  async event => {
		// Log out the user
		event.preventDefault();

		console.log(formState.inputs.password.value);
		console.log(id);

		try {
			if(formState.inputs.password.value!==formState.inputs.passwordConfirm.value){
				alert("Passwords are not equal.")
				return;

			}

			const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/reset/${id}`,
			'PATCH',
			JSON.stringify({
				pass: formState.inputs.password.value,
			}),
			{
				'Content-Type': 'application/json',
			})
			if(responseData && responseData.message === "Success"){
				alert("Your password has been restored.")
				setTimeout(()=>{
					navigate('/')
				})
			}else{

			}
		} catch (error) {
			alert(error.message)
		}

	};

	return (
		<div
			className={`my-account${isMobile ? ' mobile': ''}`}
			style={staticPage}
		>
			<div className={`wrapper`}>
		
				
				<div className='info-section'>
					<div className='info-content'>
						<div className='intro'>
							<div className='main-text'>{_('Reset Password')}</div>
							<form onSubmit={handleReset}>
							<Input
										element='input'
										errorText={_('Please enter a valid password')}
										id='password'
										label={_('New Password')}
										onInput={inputHandler}
										type='password'
										validators={[VALIDATOR_MINLENGTH(6)]}
									/>							
							<Input
										element='input'
										errorText={_('Please confirm your password')}
										id='passwordConfirm'
										label={_('Confirm Password')}
										onInput={inputHandler}
										type='password'
										validators={[VALIDATOR_MINLENGTH(6)]}

									/>		
							<div className='button-wrapper'>
									<Button
										disabled={!formState.isValid}
										type='submit'
									>
										Reset Password
									</Button>
								</div>
								</form>						
								</div>
								

	
					</div>

				
				</div>

				{/* Section 9 - Footer */}
				<div className='footer'>
					<div className='footer-wrapper'>

						<div className='footer-link'>
							<Link to='/contact'>
								{_('Contact Us')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/privacy-policy'>
								{_('Privacy Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/refund-policy'>
								{_('Refund Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/delivery-policy'>
								{_('Delivery Policy')}
							</Link>
						</div>

						<div className='footer-link'>
							<Link to='/terms-of-service'>
								{_('Terms of Service')}
							</Link>
						</div>

					</div>
				</div>
			</div>

			
		</div>
	);
};

const mapStateToProps = state => {
	return {
		responsive: state.responsive.responsive,
	};
};


export default connect(mapStateToProps)(ResetPassword);
