// Libraries
import React from 'react';

// Stylesheets
import './FavoriteButton.scss';

const FavoriteButton = ({ handleFavoriteClick }) => (
	<button
		className='favorite-button'
		onClick={handleFavoriteClick}
	>
		<i className='fa-solid fa-heart'></i>
	</button>
);

export default FavoriteButton;