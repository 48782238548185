import "./Login.scss";
//import GetStartedForm from "./GetStartedForm";
import PaymentDetails from "./PaymentDetails";
import { NavLink } from "react-router-dom";

const Login = () => {
  return (
      <div className="login-form">
        <h2>640</h2>
        <form>
          <div className="form-group">
            <label>Email</label>
            <input type="email" placeholder="Email" />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" placeholder="Password" />
          </div>
          <div className="row mb-3">
              <div className="col-md-5">
                <a href="/">Forgot password?</a>
              </div>
          </div>
          <div className="form-group">
            <button>Log In</button>
          </div>
        </form>
      </div>
  );
};

export default Login;
