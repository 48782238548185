import {
	SET_RESPONSIVE,
	TOGGLE_RESPONSIVE,
} from './types.js';

const INITIAL_STATE = {
	responsive: null,
};

const responsiveReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_RESPONSIVE:
			return {
				...state,
				responsive: action.payload,
			};

		case TOGGLE_RESPONSIVE:
			return {
				...state,
				responsive: action.payload,
			};

		default:
			return state;

	}
};

export default responsiveReducer;
