import React, { useEffect } from "react";
import { Subscriptions } from "../../lib/const";
import Card from "./Card";
import "./SubscriptionsComponent.scss";
import { getCurrentUserLocalStorage } from "../../lib";
import { useSelector } from "react-redux";
import CheckoutModal from "../../pages/checkout-modal/CheckoutModal";
import { useNavigate } from "react-router-dom";
const SubscriptionsComponent = ({marginTop, ishandleClick, user, isMobile}) => {
  const navigate = useNavigate();
  const [paymentSubmitted, setPaymentSubmitted] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState({});

  const todos = useSelector((state) => state);
  useEffect(() => {
    console.log("componentDidMount");
    console.log(isMobile);

    let currentUser = getCurrentUserLocalStorage();
    if (!currentUser) return;

    console.log("currentUser: ", currentUser);
    // Authenticate user..
    // Login the user here..
  }, []);

  const handleSubmit = (period, price, title, url) => {
    console.log("period: ", period);

    setSelectedPlan({ period, price , title, url});
    setPaymentSubmitted(true);
  };

  const handleClick = () => {
    
      // Display 'Create Account' modal
      console.log("user: ", user);
      if (user && user.userId) navigate("/my-account");
      else navigate("/user?newUser=true",user);
    };

  //Need a function that takes the period of the Card and create an object with the period and the price

  return (
    <React.Fragment >
      <div className="">
        {!paymentSubmitted && (
            <div style={{marginTop: `${marginTop !== "" ? marginTop : "10%"} `}}>
            <h1 className=" title">Choose your plan</h1>
            <div className="row subscriptions " style={{marginTop: `${isMobile ? "-2em !important": ""}`}}>
              {Subscriptions.map((data, index) => (
                <Card
                  key={index}
                  title={data.title}
                  price={data.price}
                  period={data.period}
                  features={data.features}
                  popular={data.popular}
                  buttonText={data.buttonText}
                  backgroundColor={data.backgroundColor}
                  description={data.description}
                  popularText={data.popularText}
                  handleSubmit={ ishandleClick ? handleClick : () => handleSubmit(data.period, data.price, data.title, data.url)}
                  lastComment={data.lastComment}
                  highlighted={data.highlighted}
                  special={data.special}
                  className="col-md-4"
                />
              ))}
            </div>
          </div>
        )}

        {paymentSubmitted && (
          <CheckoutModal selectedPlan={selectedPlan} ></CheckoutModal>
        )}
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsComponent;
