// Libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StickyBar from "./StickyBar";
// Components
import StartFree from "./StartFree";

// Methods
import { _ } from "../../lib";
// Constants
import { MOBILE } from "../../lib/const";

const BetaHeader = ({
  handleClick,
  handleLogin,
  handleLogout,
  user,
  isTabs,
  responsive,
}) => {
  const headerAssets = {
    "640-main-logo": `${process.env.REACT_APP_2_CDN_URL}/assets/img/alpha_640_logo.png`,
  };

  const [isValidUser, setIsValidUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Wipe anything
    setIsValidUser(false);

    if (user && user.userId) {
      setIsValidUser(true);
    }
  }, [user]);

  const handleAuthClick = () => {
    console.log("handleAuthClick()");
    console.log("isValidUser: ", isValidUser);

    if (isValidUser) {
      handleLogout();
    } else {
      handleLogin();
    }
    // const isValidUser = user && user.userId;

    // if (isValidUser)
  };

  const loggedInStyles = {
    minWidth: "15%",
    right: "0%",
  };
  const isMobile = responsive === MOBILE;

  const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 minutos en segundos

  useEffect(() => {
    // Si el tiempo llegó a 0, salir
    if (timeLeft === 0) return;

    // Crear un intervalo que decrementa el tiempo cada segundo
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Limpiar intervalo en el desmontaje
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Formatear el tiempo restante en minutos y segundos
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <div>
      
      <div className="header">
        <div className="header-home">
          <Link to="/">
            {
              <img
                alt="640-main-logo"
                className="main-logo"
                src={headerAssets["640-main-logo"]}
              />
            }
          </Link>
        </div>

        {/* VV - Rename this to log-in-out */}
        {!isTabs && (
          <div className="header-log-in">
            <span onClick={handleAuthClick}>
              {isValidUser ? _("Logout") : _("Login")}
            </span>

            {isValidUser && (
              <Link to="/my-account">
                <span>{"My Account"}</span>
              </Link>
            )}
          </div>
        )}

        {/* {isMobile?null:!isValidUser && !isTabs &&
				<div className='header-start'>
					<StartFree handleStartFreeClick={handleClick} />
				</div>} */}
      </div>
    </div>
  );
};

export default BetaHeader;
