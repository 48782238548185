// Libraries
import React from 'react';

// Components
import EditButton from '../edit-button/EditButton';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './UserInfoCard.scss';

const UserInfoCard = ({
	email,
	username,
	password,
	phoneNumber
}) => (
	
	<div className='user-info-card'>

		<div className='primary-info'>

			<div className='circle'></div>

			<div className='name'>{username}</div>

		</div>

		<div className='user-input-card'>

			<div className='user-input-container'>

				<span className='user-input-label'>{_('E-Mail')}</span>

				<span className='user-input'>{email}</span>

				<EditButton />

				<span className='divider'></span>

			</div>

			<div className='user-input-container'>

				<span className='user-input-label'>{_('Phone Number')}</span>

				<span className='user-input'>{phoneNumber}</span>

				<EditButton />

			</div>

			<div className='user-input-container'>

				<span className='user-input-label'>{_('Password')}</span>

				<span className='user-input'>
					{password}
				</span>

				<EditButton />

			</div>

		</div>

	</div>
);

export default UserInfoCard;