// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Methods
import { _ } from '../../lib/index';

// Stylesheets
import './Subheader.scss';

// Assets - VICTOR move these to server
import shortBanner from '../../assets/short-banner.jpg';
import tallBanner from '../../assets/short-banner.jpg';


const Subheader = ({
	collection,
}) => {

	let collectionDescription = 'Enter some descriptions about the collection here.. ';
	let collectionName;
	
	if (collection) {
		if (collection.name) {
			collectionName = collection.name;
			// Capitalize first letter..
			// Translate collection name
		}

		if (collection.description) {
			collectionDescription = collection.description;
			// Capitalize first letter..
			// Translate collection name
		}

	}
	
	return (
		<div className='subheader'>
			<img
				alt='artsy banner'
				src={tallBanner}
			/>

			<div className='content'>

				{/* VICTOR - this content should be conditionally rendered based on the route we are one */}

				<p className='title'>{collectionName}</p>

				{/* <input
					className='input'
					placeholder={_('Search for the perfect tools')}
					type='text'
				/> */}

				<p className='description'>{collectionDescription}</p>
				
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		collection: state.collection,
	};
};

export default connect(mapStateToProps)(Subheader);