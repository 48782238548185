// Libraries
import React from 'react';
import { connect } from 'react-redux';

// Components
import ExploreCollection from '../../components/explore-collection/ExploreCollection';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import SalesFunnel from '../../components/sales-funnel/SalesFunnel';

// Methods
import { _ } from '../../lib/index';

// Stylesheets
import './HomePage.scss';

// VICTOR - clean this up, place in its own file

// Data
const homeAssets = {
	'crt': `${process.env.REACT_APP_CDN_URL}/home-page/crt.mp4`,
	'text-presets': `${process.env.REACT_APP_CDN_URL}/home-page/text-presets.mp4`,
	'transitions': `${process.env.REACT_APP_CDN_URL}/home-page/transitions.mp4`,
	// 'textures': `${process.env.REACT_APP_CDN_URL}/home-page/textures.mp4`,
	
};

// VICTOR - clean this up!

const data = [
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
	{
		file: `${process.env.REACT_APP_CDN_URL}/collection-assets/text-presets/one.mp4`, 
		image:  `${process.env.REACT_APP_CDN_URL}/collection-images/one.png`,
	},
];

const HomePage = ({
	user,
}) => {

	let username = '';

	if (user && user.username) {
		username = ' ' + user.username;
	}

	const generateAssetURL = () => {};

	let content;

	if (user && user.username) {
		content = (
			<React.Fragment>
				{/* <PageHeader title={`Welcome${username}!`} /> */}
				<PageHeader title={_('Welcome Back Victor!')} />
				{/* VICTOR - remove the PageHeader! */}

				{/* Explore Collections */}

				<ExploreCollection
					assetURL={homeAssets['text-presets']}
					collectionName={_('Text Presets')}
					previewData={data}
					urlRoute={'text-presets'}
				/>

				<ExploreCollection
					assetURL={homeAssets['crt']}
					collectionName={_('CRT')}
					previewData={data}
					urlRoute={'motion-graphics'}
				/>

				<ExploreCollection
					assetURL={homeAssets['transitions']}
					collectionName={_('Text Transitions')}
					previewData={data}
					urlRoute={'transitions'}
				/>
			</React.Fragment>
		);
	} else {
		content = (
			// Sales Funnel
			<SalesFunnel
				assetURL={homeAssets['sales-funnel2']}
			/>
		);
	}

	return (
		<div className='home-page'>
			{/* VICTOR - if we are on the home page hide the Subheader section */}

			{content}

			<Footer />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(HomePage);