import {
	CLOSE_MODAL,
	END_MODAL_LOADING,
	OPEN_MODAL,
	START_MODAL_LOADING,
} from './types.js';

const INITIAL_STATE = {
	isModalLoading: false,
	// isModalOpen: false,
	isModalOpen: true,
};

const modalReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CLOSE_MODAL:
			return {
				...state,
				isModalOpen: false,
			};

		case END_MODAL_LOADING:
			return {
				...state,
				isModalLoading: false,
			};

		case OPEN_MODAL:
			return {
				...state,
				isModalOpen: true,
			};

		case START_MODAL_LOADING:
			return {
				...state,
				isModalLoading: true,
			};

		default:
			return state;
	}
};

export default modalReducer;
