// Libraries
import React from 'react';

// Methods
import { _ } from '../../lib';

// Stylesheets
import './LutsDropdown.scss';

const LutsDropdown = () => {
	return (
		<span className='luts-dropdown'>
			<select
				id='luts'
				name='luts'
			>
				<option value=''>{_('Choose your log profile:')}</option>
				<option value='bm-6k'>{_('Black Magic 6k Film')}</option>
				<option value='bm-4k'>{_('Black Magic 4k Film')}</option>
				<option value='red-kom'>{_('RED Komodo Rec 709')}</option>
				<option value='red-sca'>{_('RED Scarlet Rec 709')}</option>
				<option value='sony'>{_('Sony A7s Log')}</option>
				<option value='gh5'>{_('GH5 Log')}</option>
				<option value='gh6'>{_('GH6 Log')}</option>
			</select>
		</span>
	);
};

export default LutsDropdown;