// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

// Components
import AssetCard from '../../components/asset-card/AssetCard';
import EditButton from '../../components/edit-button/EditButton';
import PageHeader from '../../components/page-header/PageHeader';
import UserInfoCard from '../../components/user-info-card/UserInfoCard';

// Methods
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { setCurrentUser } from '../../redux/actions.js';
import { _ } from '../../lib';

// Stylesheets
import './AccountPage.scss';

const AccountPage = ({
	user,
}) => {
	const auth = useContext(AuthContext);

	const { isLoading, error, sendRequest, clearError } = useHttpClient();

	const [loadedUserData, setLoadedUserData] = useState(null);

	useEffect(() => {
		// Make an IIFEE
		const fetchUserData = async () => {
			try {
				// VICTOR - for now let anyone hit the endpoint
				// if (auth.isLoggedIn) {

					console.log(process.env.REACT_APP_BACKEND_URL);

					// const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}`);
					const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/62bf4de6fd17b52c79740683`);

					// Set the current user..

					// setLoadedUserData(responseData);

					// const { createdAt, username, email, id, subscribed } = responseData.user;

					// props.setCurrentUser({ createdAt, username, email, id, subscribed });

				// }
			} catch (err) {
				console.log('Error: ', err);
			}
		};
		fetchUserData();
	}, []);

	let username;
	let email;

	if (user) {
		username = user.username;
		email = user.email;
	}

	return (
		<div className='account-page'>

			{user &&
				<>
					<UserInfoCard
						email={email}
						firstName={username}
					/>

					<PageHeader title={_('My Account')} />

					<div className='content-wrapper'>
						{/* {
							data.map(item => {
								<AssetCard

								/>
							})
						} */}

					</div>
				</>
			}

		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCurrentUser,
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);