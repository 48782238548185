import { combineReducers } from 'redux';

import collectionReducer from './collectionReducer';
import errorReducer from './errorReducer';
import modalReducer from './modalReducer';
import responsiveReducer from './responsiveReducer';
import userReducer from './userReducer';
import subscriptionReducer from './subscriptionReducer';

const rootReducer = combineReducers({
	collection: collectionReducer,
	error: errorReducer,
	modal: modalReducer,
	responsive: responsiveReducer,
	user: userReducer,
	subscription: subscriptionReducer
});

export default rootReducer;

// VICTOR - come back and add these
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// import cartReducer from './cart/cart.reducer';

// const persistConfig = {
// 	key: 'root',
// 	storage: storage,
// 	// whitelist: ['cart']
// };

// export default persistReducer(persistConfig, rootReducer);