// Libraries
// Libraries
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Components
import { PayPalButton } from "react-paypal-button-v2";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";

import { _, addToCartFBPixel, googleTag } from "../../lib";
import { sendAuthorizeRequest } from "../../lib/authorize";
import { purchaseEventFBPixel } from "../../lib";
import { useForm } from "../../shared/hooks/form-hook";

import { AUTHENTICATE, AUTHORIZE, CHECKOUT, MOBILE } from "../../lib/const";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
// Stylesheet
import "./PaymentDetails.scss";

const PaymentDetails = ({ user }) => {
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [isAuthorizeMode, setIsAuthorizeMode] = useState(false);

  const navigate = useNavigate();

  const termsEl = useRef();

  const [formState, inputHandler, setFormData] = useForm(
    {
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "",
        isValid: false,
      },

      cardNumberHashed: {
        value: "",
        isValid: false,
      },
      expirationDateHashed: {
        value: "",
        isValid: false,
      },
      cardCodeHashed: {
        value: "",
        isValid: false,
      },
    },
    true
  );

  useEffect(() => {
    setIsPaymentEnabled(agreedToTerms);
  }, [agreedToTerms]);

  const handleTermsChange = (e) => {
    let target = e.target;

    if (!target) return;

    setAgreedToTerms(target.checked);
  };

  const handleChange = (text) => {
    let textTemp = text;
    if (textTemp[0] !== "1" && textTemp[0] !== "0") {
      textTemp = "";
    }
    if (textTemp.length === 2) {
      if (
        parseInt(textTemp.substring(0, 2)) > 12 ||
        parseInt(textTemp.substring(0, 2)) === 0
      ) {
        textTemp = textTemp[0];
      } else if (this.formState.expirationDateHashed.length === 2) {
        textTemp += "/";
      } else {
        textTemp = textTemp[0];
      }
    }
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    sendAuthorizeRequest(user, formState.inputs)
      .then((response) => {
        alert(response.data.message);
        window.location.reload(false);
        purchaseEventFBPixel(user.email);
        googleTag("purchase", user.email);
      })
      .catch((error) => alert("error: " + error));
  };

  return (
    <React.Fragment>
      <div className="credit-card-form">
        {/* Head Section */}

        {/* Body Section */}
        <div className="container body">
          <div className="row">
            <span className="title">{_("Payment Details")}</span>
          </div>

          <span>{_("Secured payment powered by ")}</span>
          <span className="fontNet">Authorize.Net</span>

          <img
            className="authorize-image mt-2"
            alt="visa"
            src="https://s3.us-east-2.amazonaws.com/640studio.io/assets/img/Free-Payment-Method-and-Credit-Card-Icon-Set_clipped_rev_1.png"
          ></img>

          <form onSubmit={authSubmitHandler} className="mt-2">
            <div className="row mt-2 gy-3 form-group">
              <Input
                element="input"
                id="firstName"
                label={_("First Name")}
                placeholder="John"
                onInput={inputHandler}
                type="text"
                validators={[VALIDATOR_REQUIRE()]}
                width="4"
              />

              <Input
                element="input"
                id="lastName"
                label={_("Last Name")}
                placeholder="Doe"
                onInput={inputHandler}
                type="text"
                validators={[VALIDATOR_REQUIRE()]}
                width="4"
              />

              <Input
                element="input"
                id="cardCodeHashed"
                label={_("CVC")}
                onInput={inputHandler}
                placeholder="123"
                type="number"
                validators={[VALIDATOR_REQUIRE()]}
                width="4"
              />
            </div>
            <div className="row mt-2 form-group">
              <Input
                element="input"
                id="cardNumberHashed"
                label={_("Card Number")}
                onInput={inputHandler}
                placeholder="xxxx xxxx xxxx 1234"
                type="number"
                validators={[VALIDATOR_REQUIRE()]}
              />
            </div>

            <div className="row mt-2 form-group">
              <Input
                element="input"
                id="expirationDateHashed"
                label={_("Valid Through")}
                onInput={inputHandler}
                placeholder="MM/YY"
                type="month"
                validators={[VALIDATOR_REQUIRE()]}
                width="4"
                maxValue={5}
              />
            </div>

            <div className="row mt-2 pb-3">
              <div className="col-md-12">
                <button
                  className={`btn button mt-2`}
                  disabled={!formState.isValid}
                  type="submit"
                >
                  Proceed
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

//const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps)(PaymentDetails);

//export default PaymentDetails;
