// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Stylesheets
import './CollectionNavigation.scss';

import { setCollectionSelectedKeyword } from '../../redux/actions.js';

import { LUTS } from '../../lib/const';

const CollectionNavigation = ({
	categories,
	collectionName,
	setCollectionSelectedKeyword,
}) => {

	let isLuts = false;

	if (collectionName === LUTS) isLuts = true;

	const handleClick = (e) => {
		let target = e.target;

		if (!target) return;

		let keyword = target.getAttribute('keyword');

		keyword = keyword.toLowerCase();

		setCollectionSelectedKeyword(keyword);
	}

	return (
		<div className={`collection-navigation ${isLuts ? 'is-luts' : ''}`}>
			{categories && categories.map((filteredAsset, idx) => (
				<li
					className='filtered-asset'
					key={idx}
					keyword={filteredAsset}
					onClick={handleClick}
				>
					{filteredAsset}
				</li>
			))}
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCollectionSelectedKeyword,
	}, dispatch);
};

export default connect(null, mapDispatchToProps)(CollectionNavigation);