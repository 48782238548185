// Libraries
import React, { useState, useRef } from 'react';

// Components
import ActivateAsset from '../activate-asset/ActivateAsset';
import FavoriteButton from '../favorite-button/FavoriteButton';

import {
	alphabetizeArrayItems,
	capitalizeAllWords,
	capitalizeArrayItems,
} from '../../lib';

// Stylesheets
import './SoundCard.scss';

const SoundCard = props => {
	const {
		filePath,
		imagePath,
		keywords,
		name,
	} = props;

	// VICTOR - Create helper function for these 3 - 'ConstructAssetPaths'

	let fullImagePath;
	let fullFilePath;
	let modifiedKeywords;

	// Organize the keywords
	if (keywords) {
		modifiedKeywords = capitalizeArrayItems(keywords);
		modifiedKeywords = alphabetizeArrayItems(modifiedKeywords);
		modifiedKeywords = modifiedKeywords.join(', ')

	}

	// Construct the full image and file paths
	if (filePath && imagePath) {
		fullImagePath = `${process.env.REACT_APP_CDN_URL}/collection-images/${imagePath}`;
		fullFilePath = `${process.env.REACT_APP_CDN_URL}/collection-assets/${filePath}`;
	}

	const currentAudio = useRef();
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState('');
	const [totalDuration, setTotalDuration] = useState('');

	const handleClick = e => {
		if (!isPlaying) {
			currentAudio.current.play();
		} else {
			currentAudio.current.pause();
		}
		setIsPlaying(!isPlaying);
	};

	const formatSecondsAsTime = (secs, format) => {
		let hr  = Math.floor(secs / 3600);
		let min = Math.floor((secs - (hr * 3600)) / 60);
		let sec = Math.floor(secs - (hr * 3600) -  (min * 60));

		if (min < 10) {
			min = '0' + min;
		}

		if (sec < 10) {
			sec  = '0' + sec;
		}

		return min + ':' + sec;
	};

	const updateTrackTime = () => {
		// VICTOR - add a check, if currTime equals duration then reset


		let currTime = Math.floor(currentAudio.current.currentTime).toString();
		let duration = Math.floor(currentAudio.current.duration).toString();

		if (currTime === duration) {
			setCurrentTime('00:00');
			setIsPlaying(false);
			return;
		}

		setCurrentTime(formatSecondsAsTime(currTime));

		if (isNaN(duration)) {
			setTotalDuration('00:00');
		} else {
			setTotalDuration(formatSecondsAsTime(duration));
		}
	};

	return (
		<tr className='sound-card'>

			{/* Image Cell */}
			<td className='asset-image'>
				<img alt='' src={fullImagePath} />
			</td>

			{/* Pause/Play Icon Cell */}
			<td className='asset-status' onClick={handleClick}>
				<span className='play-status'>
					<i className={`fa-5x fa fa-${isPlaying ? 'pause' : 'play'}-circle-o`}></i>
				</span>

				<audio
						controls
						controlsList='nodownload'
						onTimeUpdate={updateTrackTime}
						ref={currentAudio}
					>
						<source
							src={fullFilePath}
							type='audio/wav'
						/>
						Your browser does not support the audio element.
				</audio>
			</td>

			{/* File Name Cell */}
			<td>
				<span>{capitalizeAllWords(name)}</span>
			</td>

			{/* Asset Duration */}
			<td>
				<span className='current-time'>{currentTime} </span>-
				<span className='total-duration'> {totalDuration}</span>
			</td>

			{/* VICTOR - removing the soundwave for now */}
			{/* Asset Soundwave */}
			{/* <td className='asset-soundwave'>
				<img src={`${process.env.REACT_APP_CDN_URL}/collection-assets/burst.png`} alt="" /> */}
				{/* <span>SoundWave</span> */}
			{/* </td> */}

			{/* Asset Category */}
			<td className='asset-keywords'>
				<span>{modifiedKeywords}</span>
			</td>

			{/* Download Button */}
			<td>
				<FavoriteButton
					handleFavoriteClick={() => alert('Favorite!')}
				/>

				<ActivateAsset />
			</td>
		</tr>
	);
};

export default SoundCard;