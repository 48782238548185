// Redux Actions
import { subscribeUser, unsubscribeUser } from "../redux/actions";

// Constants
import { PAYPAL_CLIENT_ID, PAYPAL_SECRET_KEY } from "./const";

import { Buffer } from "buffer";

import axios from "axios"
// Inputs a string
// Returns a translated string
export const _ = (str) => {
	return str;
};

export const alphabetizeArrayItems = (arr) => {
	return arr.sort();
};

export const capitalizeAllWords = (str) => {
	let arr = str.split(" ");

	let newArr = arr.map((el) => el[0].toUpperCase() + el.slice(1));

	return newArr.join(" ");
};

export const capitalizeArrayItems = (arr) => {
	return arr.map((el) => capitalizeAllWords(el));
};

export const makeArrayUnique = (arr) => {
	return [...new Set(arr)];
};

export const prepareCollectionCategories = (arr) => {
	let collectionCategories = makeArrayUnique(arr);

	collectionCategories = alphabetizeArrayItems(collectionCategories);

	collectionCategories = capitalizeArrayItems(collectionCategories);

	return collectionCategories;
};

// Inputs a url slug
// Returns proper title from given slug
export const unslugify = (slug) => {
	let titleWords = slug.split("-");

	for (let i = 0; i < titleWords.length; i++) {
		titleWords[i] = titleWords[i][0].toUpperCase() + titleWords[i].substring(1);
	}

	return titleWords.join(" ");
};

// Checks if local storage has a current user
export const getCurrentUserLocalStorage = () => {
	console.log("getCurrentUserLocalStorage()");

	// Check our local storage for a token
	const storedData = JSON.parse(localStorage.getItem("userData"));

	if (
		storedData &&
		storedData.token &&
		storedData.userId &&
		new Date(storedData.expiration) > new Date()
	) {
		return {
			expiration: new Date(storedData.expiration),
			token: storedData.token,
			userId: storedData.userId,
		};
	}
	return null;
};

export const removeCurrentUserLocalStorage = () => {
	console.log("removeCurrentUserLocalStorage()");
	localStorage.removeItem("userData");
	localStorage.clear()
    window.location.reload(false);
};

// Needs token and userId - everything saved in local storage..
export const authenticateUser = async (token, userId) => {
	console.log("authenticateUser()");
	console.log("token: ", token);
	console.log("userId: ", userId);

	try {
		// Token and userId are valid, now fetch the user's data
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`
		);

		console.log("response: ", response);

		const responseData = await response.json();

		console.log("responseData: ", responseData);

		if (!responseData.user) return;

		const {
			// activeAssets,
			createdAt,
			email,
			isSubscribed,
			password,
			payPalToken,
			subscriptionData,
			subscriptionID,
			username,
			platform
		} = responseData.user;

		let currentUser = {
			// activeAssets: activeAssets,
			createdAt: createdAt,
			email: email,
			isSubscribed: isSubscribed,
			password: password, // VV - hashed
			payPalToken: payPalToken,
			subscriptionData: subscriptionData,
			subscriptionID: subscriptionID,
			// subscription: subscription, // VV - update naming
			token: token,
			userId: userId,
			username: username,
			platform: platform
		};

		console.log("currentUser: ", currentUser);

		return currentUser;
	} catch (err) {
		console.log("Error: ", err);
	}
};

// Activate & Cancel Subscriptions
export const activatePayPalSubscription = async (
	userID,
	subscriptionData,
	subscriptionID,
	email,
	subscriptionType
) => {
	console.log("activatePayPalSubscription()");

	console.log("userID: ", userID);
	console.log("subscriptionData: ", subscriptionData);
	console.log("subscriptionID: ", subscriptionID);

	// 1. First, we have to subscribe the user on PayPal's API..
	// since we are in this function call, we can assume that's already the case
	// 2. Then, we have to update the user on our Back-End
	// 3. Finally, we have to update the user on our Front-End (Redux + LocalStorage)

	try {
		const responseData = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/user/subscribe/${userID}`,
			{
				method: 'PATCH',
				body: JSON.stringify({
					isSubscribed: true,
					subscriptionData: subscriptionData,
					subscriptionID: subscriptionID,
					subscriptionType: subscriptionType
				}),
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		// Check if the response was a success
		console.log("responseData: ", responseData);
		if (responseData) {
			// Update redux state
			purchaseEventFBPixel(email)
			subscribeUser(subscriptionData);
			return true;
		}
	} catch (err) {
		console.log("Error: ", err);
	}

	return false;
};

export const cancelAuthorizeSubscription = async (userId, email) => {
	const responseData = await fetch(
		`${process.env.REACT_APP_BACKEND_URL}/payments/unsubcribe/authorize`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body:JSON.stringify({
				email: email,
				subscriptionId:userId
			})
		}
	);

	console.log("responseData: ", responseData);

	if (responseData.status == 200) {
		// Update redux state
		return true;
	}
}

export const cancelPayPalSubscription = async (
	userID,
	subscriptionData,
	subscriptionID,
	email
) => {
	console.log("cancelPayPalSubscription()");

	console.log("userID: ", userID);
	console.log("subscriptionData: ", subscriptionData);
	console.log("subscriptionID: ", subscriptionID);

	// 1. First, we have to cancel the subscription on PayPal's API
	// 2. Then, we have to update the user on our Back-End
	// 3. Finally, we have to update the user on our Front-End (Redux + LocalStorage)

	try {
		const {access_token} = await getAccessToken();

		const subscriptionDetails = await getPayPalSubscriptionDetails(
			userID,
			subscriptionData,
			subscriptionID,
			access_token
		);
		if(subscriptionDetails && subscriptionDetails.status !== 'CANCELED'){
			const cancelResponse = await fetch(
				`${process.env.REACT_APP_PAYPAL_URL}/v1/billing/subscriptions/${subscriptionID}/cancel`,
				{
					method: "POST",
					body: JSON.stringify({
						reason: "Cancel subscription",
					}),
					headers: {
						Authorization: "Bearer " + access_token,
						"Content-Type": "application/json",
					},
				}
			);
			const cancelData =  cancelResponse.status;

			if (cancelData < 200 || cancelData > 299){
				return false;
			}
		}
		
		try {
			const responseData = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user/unsubscribe/${userID}`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			console.log("responseData: ", responseData);

			if (responseData) {
				// Update redux state
				unsubscribeUser();
				return true;
			}
		} catch (err) {
			console.log("Error: ", err);
		}
			
		
	} catch (err) {
		console.log("Error: ", err);
	}
	return false;
};

export const reactivatePayPalSubscription = async (
	userID,
	subscriptionData,
	subscriptionID,
	payPalAuthToken,
	email
) => {
	try {
		const {access_token} = await getAccessToken();

		const subscriptionDetails = await getPayPalSubscriptionDetails(
			userID,
			subscriptionData,
			subscriptionID,
			access_token
		);
		if(subscriptionDetails && subscriptionDetails.status === 'CANCELED'){
			const reactivateResponse = await fetch(
				`${process.env.REACT_APP_PAYPAL_URL}/v1/billing/subscriptions/${subscriptionID}/activate`,
				{
					method: "POST",
					body: JSON.stringify({
						reason: "Reactivate Subscription",
					}),
					headers: {
						Authorization: "Bearer " + access_token,
						"Content-Type": "application/json",
					},
				}
			);
			const reactivateData = await reactivateResponse.status;

			if (reactivateData !== 204){
				return false;
			}
		}
		
		try {
			const responseData = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user/subscribe/${userID}`,
				{
					method: 'PATCH',
					body: JSON.stringify({
						isSubscribed: true,
						subscriptionData: subscriptionData,
						subscriptionID: subscriptionID,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
	
			// Check if the response was a success
			console.log("responseData: ", responseData);
			if (responseData) {
				// Update redux state
				subscribeUser(subscriptionData);
				return true;
			}
		} catch (err) {
			console.log("Error: ", err);
		}
			
		
	} catch (err) {
		console.log("Error: ", err);
	}
	return false;
};

export const getPayPalSubscriptionDetails = async (
	userID,
	subscriptionData,
	subscriptionID,
	payPalAuthToken
) => {
	console.log("getPayPalSubscriptionDetails()");

	try {
		const access_token = payPalAuthToken ? payPalAuthToken : await getAccessToken().access_token;

		console.log("accessToken: ", access_token);

		// Check the status of the subscription
		const detailsResponse = await fetch(
			`${process.env.REACT_APP_PAYPAL_URL}/v1/billing/subscriptions/${subscriptionID}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + access_token,
					"Content-Type": "application/json",
				},
			}
		);

		console.log("detailsResponse: ", detailsResponse);

		const detailsData = await detailsResponse.json();

		console.log("detailsData: ", detailsData);

		if (detailsData) {
			return detailsData;
		}
	} catch (err) {
		console.log("Error: ", err);
	}
	return;
};

const getAccessToken = async () => {
	var urlencoded = new URLSearchParams();
	urlencoded.append("grant_type", "client_credentials");
	const detailsResponse = await fetch(
		`${process.env.REACT_APP_PAYPAL_URL}/v1/oauth2/token`,
		{
			method: "POST",
			headers: {
				Authorization:
					"Basic " +
					Buffer.from(
						process.env.REACT_APP_PAYPAL_CLIENT_ID +
						":" +
						process.env.REACT_APP_PAYPAL_SECRET
					).toString("base64"),
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: urlencoded,
		}
	);
	console.log("detailsResponse: ", detailsResponse);

	const detailsData = await detailsResponse.json();

	console.log("detailsData: ", detailsData);

	if (detailsData) {
		return detailsData;
	}
};


export const addToCartFBPixel = (email) => {
	window.fbq('track', 'AddToCart', {
		em:email
	})
}

export const purchaseEventFBPixel = async (email) => {
	window.fbq('track', 'Purchase', {
		em:email,
		currency:"USD",
		value:14.99

	})

}

export const freeTrialFBPixel = async (email) => {
	let ip = await getIpClient();
	window.fbq('track', 'StartTrial', {
		client_ip_address:ip,
		em:email
	})

}

export const googleTag = async (eventName, email) => {
	window.dataLayer = window.dataLayer || [];
		let event = {
			event: eventName,
			event_name: eventName,
			eventValue: email,
		}
		window.dataLayer.push(event);

}

async function getIpClient() {
	try {
	  const response = await axios.get('https://api.ipify.org?format=json');
	  return response.ip
	} catch (error) {
	  console.error(error);
	}
  }
  


// Update redux state with this logged in user

// Set local storage to be authenticated with this user's credentials

// props.authenticateUser(currentUser);

// Set the token in localStorage
// setCurrentUserLocalStorage(userId, token);

// VICTOR What does this even do?
// auth.login(responseData.userId, responseData.token);

// Successful login for this user, take them to the 'My Account' page
// navigate('/my-account');
