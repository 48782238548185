/*

import "./Form.scss";

const GetStartedForm = () => {
  return (
    <div className="login-form">
      <h2>Get Started</h2>
      <form>
        <div className="form-group">
          <label>Username</label>
          <input type="username" />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input type="password" />
        </div>
        <div className="form-group">
          <button>Let's Get Started</button>
        </div>
      </form>
    </div>
  );
};
export default GetStartedForm;
*/

// Libraries
import React, { useContext, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
// Methods
// 1. Redux
import { setCurrentUser } from "../../redux/actions.js";
// 2. Context API
import { AuthContext } from "../../shared/context/auth-context";
// 3. Custom Hooks
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
// 4. Helper Functions
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { _, addToCartFBPixel, googleTag } from "../../lib";

// Stylesheets
import "./GetStartedForm.scss";

const AuthenticateModal = (props) => {
  console.log("AuthenticateModal()");

  const navigate = useNavigate();

  const auth = useContext(AuthContext);

  const [isLoginMode, setIsLoginMode] = useState(true);

  const [isForgotMode, setIsForgotMode] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        isValid: false,
        value: "",
      },
      password: {
        isValid: false,
        value: "",
      },
      passwordConfirm: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const authSubmitHandler = async (event) => {
    console.log("authSubmitHandler()");

    event.preventDefault();

    if (isForgotMode) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/reset`,
          "POST",
          JSON.stringify({
            email: formState.inputs.email.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData && responseData.message === "success") {
          alert(
            "An email will be sent to you with the instructions to reset your password. It may take about 15-30 min. Remember to check your spam"
          );
        }
      } catch (error) {
        alert("There was a problem!");
      }
    } else if (isLoginMode) {
      // Logging in an existing user
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          "POST",
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        // Check that we got something valid back from the server
        if (responseData && responseData.token && responseData.userId) {
          // User was authenticated sucessfully
          const { email, token, userId } = responseData;

          try {
            // User inputs are valid, now fetch the user's data
            const userData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/user/${userId}`
            );

            const {
              activeAssets,
              createdAt,
              password,
              subscriptionData,
              isSubscribed,
              username,
            } = userData.user;

            // VV - construct the entire object here instead..
            let currentUser = {
              activeAssets: activeAssets,
              createdAt: createdAt,
              email: email,
              password: password, // VV - hashed
              subscriptionData: subscriptionData, // VV - update naming
              isSubscribed: isSubscribed,
              token: token,
              userId: userId,
              username: username,
            };
            console.log("currentUser: ", currentUser);

            // Update redux state with this logged in user

            // Set local storage to be authenticated with this user's credentials

            props.setCurrentUser(currentUser);

            // Set the token in localStorage
            setCurrentUserLocalStorage(token, userId);

            // VICTOR What does this even do?
            // auth.login(responseData.userId, responseData.token);

            // Successful login for this user, take them to the 'My Account' page
            navigate("/my-account");
          } catch (err) {
            console.log(err.message);
          }
        }
      } catch (err) {
        alert(err.message);
      }
    } else {
      // Signing up a new user
      console.log("Signing up a new user..");
      if (
        formState.inputs.passwordConfirm.value !==
        formState.inputs.password.value
      ) {
        alert("The passwords are not the same.");
        return;
      }
      try {
        console.log();
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/signup`,
          "POST",
          JSON.stringify({
            username: formState.inputs.username.value,
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        if (responseData) {
          if (responseData.token && responseData.userId) {
            // User was created and authenticated successfully
            addToCartFBPixel(formState.inputs.email.value);
            googleTag("addToCart", formState.inputs.email.value);

            console.log("responseData: ", responseData);

            const { token, userId } = responseData;

            let userObject = {
              email: responseData.email,
              token: responseData.token,
              userId: responseData.userId,
            };

            try {
              // Now fetch user data
              const userData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/user/${responseData.userId}`
              );

              userObject = {
                ...userObject,
                createdAt: userData.user.createdAt,
                username: userData.user.username,
                // subscription: userData.user.subscribed, // VV - needs to be fixed
                password: userData.user.password, // VV - hashed
              };

              props.setCurrentUser(userObject);
              setCurrentUserLocalStorage(token, userId);

              // Show Checkout Modal
              //props.displayCheckoutModal();
              navigate("/paymentDetails")
            } catch (err) {
              debugger;
              alert(err.message);
            }
          }
        }

        // auth.login(responseData.userId, responseData.token);

        // VV - LOGIN USER
        // props.authenticateUser(responseData);

        // try {

        // 	const userData = await sendRequest(
        // 		`${process.env.REACT_APP_BACKEND_URL}/user/${responseData.userId}`
        // 	);

        // 	console.log('userData: ', userData);

        // 	props.authenticateUser(userData);

        // } catch (err) {
        // 	console.log('Error: ', err);
        // }
      } catch (err) {
        debugger;
        alert(err.message);
      }
    }
  };

  const handleForgottenPassword = () => {
    console.log("handleForgottenPassword()");

    // Prompt user to send their e-mail, check if this e-mail is associated with an account
    // If not, display the message and return
    // If it is, send an e-mail to the found e-mail with instructions on resetting password
    // Update the DB and confirm user can log in again successfully with new credentials
  };

  const setCurrentUserLocalStorage = (token, userId) => {
    console.log("setCurrentUserLocalStorage()");

    console.log("token: ", token);
    console.log("userId: ", userId);

    const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60);

    console.log("tokenExpirationDate: ", tokenExpirationDate);

    localStorage.setItem(
      "userData",
      JSON.stringify({
        expiration: tokenExpirationDate.toISOString(),
        token: token,
        userId: userId,
      })
    );
  };

  const switchModeHandler = () => {
    console.log("switchModeHandler()");

    console.log("isLoginMode: ", isLoginMode);

    // Login Mode
    if (!isLoginMode) {
      console.log("Login Mode..");

      setFormData(
        {
          ...formState.inputs,
          username: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );

      // Signup Mode
    } else {
      console.log("Signup Mode..");

      setFormData(
        {
          ...formState.inputs,
          username: {
            isValid: false,
            value: "",
          },
        },
        false
      );
    }

    setIsLoginMode((prevMode) => !prevMode);
    setIsForgotMode(false);
  };

  const switchModalToForgot = () => {
    setFormData(
      {
        ...formState.inputs,
        username: undefined,
        password: { isValid: true, value: "" },
      },
      formState.inputs.email.isValid && formState.inputs.password.isValid
    );
    setIsForgotMode((prevMode) => !prevMode);
  };

  const errorHandler = () => {
    clearError();
  };

  useEffect(() => {
    console.log("props.createAccount: ", props.createAccount);

    setIsLoginMode(!props.createAccount);
  }, [props.createAccount]);

  return (
    <div className="authentication-modal">
      {isLoading && <LoadingSpinner asOverlay />}

      {!isLoading && (
        <React.Fragment>
          {/* Head Section */}
          <div className="head">
            <span className="head-title">
              {isLoginMode
                ? !isForgotMode
                  ? _("640 Studio")
                  : _("Reset Password")
                : _("Get started")}
            </span>
            <span className="message">
              {isLoginMode
                ? _("Access your account")
                : //_('Start today! We\'ll give you a free week, on us.')
                  _("Start today!")}
            </span>
          </div>

          {/* Body Section */}
          <div className="body">
            <span className="selected-plan">
              <form onSubmit={authSubmitHandler}>
                {!isLoginMode && (
                  <Input
                    element="input"
                    errorText={_("Please enter a valid username")}
                    id="username"
                    label={_("Username")}
                    onInput={inputHandler}
                    type="text"
                    validators={[VALIDATOR_REQUIRE()]}
                  />
                )}

                <Input
                  element="input"
                  errorText={_("Please enter a valid e-mail address")}
                  id="email"
                  label={_("E-Mail")}
                  onInput={inputHandler}
                  type="email"
                  validators={[VALIDATOR_EMAIL()]}
                />

                {!isForgotMode && (
                  <Input
                    element="input"
                    errorText={_(
                      "Please enter a valid password, at least 6 characters long"
                    )}
                    id="password"
                    label={_("Password")}
                    onInput={inputHandler}
                    type="password"
                    validators={[VALIDATOR_MINLENGTH(6)]}
                  />
                )}

                {!isForgotMode && !isLoginMode && (
                  <Input
                    element="input"
                    id="passwordConfirm"
                    label={_("Confirm Password")}
                    onInput={inputHandler}
                    type="password"
                    validators={[VALIDATOR_REQUIRE()]}
                  />
                )}

                {/* VV -12/10/22 hiding this for now, come back and integrate it once live */}
                {/* {isLoginMode &&
									<div className='forgot-password'>
										<span onClick={handleForgottenPassword}>
											{_('Forgot your password?')}
										</span>
									</div>
								} */}

                <div className="button-wrapper">
                  <Button disabled={!formState.isValid} type="submit">
                    {isLoginMode
                      ? isForgotMode
                        ? _("Reset Password")
                        : _("Login")
                      : _("Let's Get Started")}
                  </Button>
                </div>

                <div className="account-message">
                  <span className="question-prompt">
                    {isLoginMode
                      ? _("Don't have an account?")
                      : _("Already have an account?")}
                  </span>
                  <span className="cta" onClick={switchModeHandler}>
                    {isLoginMode ? _("Create Account") : _("Login")}
                  </span>
                </div>

                <div className="account-message">
                  <span
                    className="question-prompt cta"
                    onClick={switchModalToForgot}
                  >
                    {isLoginMode
                      ? !isForgotMode
                        ? _("Forgot your password?")
                        : _("")
                      : _("")}
                  </span>
                </div>
              </form>
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCurrentUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateModal);
