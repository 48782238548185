// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import PreviewCard from '../preview-card/PreviewCard';

// Methods
import {
	_,
} from '../../lib/index';

import {
	setCollectionName,
} from '../../redux/actions.js';

// Stylesheets
import './ExploreCollection.scss';

const ExploreCollection = props => {
	const {
		assetURL,
		collectionName,
		previewData,
		urlRoute,
	} = props;

	const navigate = useNavigate();

	let title = `Explore ${collectionName}`;
	title = _(title);

	const handleClick = () => {
		console.log('handleClick()');

		// VICTOR - get this working on every click!

		navigate('/collection/' + urlRoute);

		// props.setCollectionName(urlRoute);
	};

	return (
		<div
			className='explore-collection'
			onClick={handleClick}
		>
			<div className='title'>
				{title}
			</div>

			<div className='content'>

				<div className='main'>

					<div className='video-wrapper'>
						<video
							autoPlay
							loop
							muted
						>
							<source
								src={assetURL}
								type='video/mp4'
							/>
							{/* VICTOR */}
							{/* Backup format in case the initial one fails */}
							{/* <source src='.ogg' type='video/ogg'> */}
							Your browser does not support the video tag.
						</video>
					</div>

				</div>

				<div className='secondary'>
					<div className='preview'>
						{previewData && previewData.map((item, idx) => (
							<PreviewCard
								data={item}
								key={idx}
							/>
						))}
					</div>
				</div>


			</div>
		</div>
	);
};

// const mapStateToProps = state => {
// 	return {
// 	};
// };

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		setCollectionName,
	}, dispatch);
};

export default connect(null, mapDispatchToProps)(ExploreCollection);

// NOTES - VICTOR
// - Add onclick re-routes
// - Add correct assets to display for the explore collection secondary content
// - Add video controls