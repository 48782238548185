import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import AuthenticateModal from "../../pages/authenticate-modal/AuthenticateModal";
import SubscriptionsComponent from "./SubscriptionsComponent";
import { AuthContext } from '../../shared/context/auth-context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import BetaHeader from "./BetaHeader";
import {
	_,
	cancelPayPalSubscription,
	cancelAuthorizeSubscription,
	getCurrentUserLocalStorage,
	removeCurrentUserLocalStorage,
	reactivatePayPalSubscription
} from '../../lib';
import CheckoutModal from "../../pages/checkout-modal/CheckoutModal";

const UserView = ({user}) => {

    const todos = useSelector(state => state);

    const auth = useContext(AuthContext);
    const [logoutUsers, setLogOutUser] = useState(false);

    const navigate = useNavigate();
    const [authenticateModal, setAuthenticateModal] = useState(true);
    const [subscriptions, setSubscriptions] = useState(false);
    const [checkoutModal, setCheckoutModal] = useState(false);
    //I need user state here
    useEffect(() => {
		console.log('componentDidMount');
				let currentUser = getCurrentUserLocalStorage();
			if (!currentUser) return;
	
			console.log('currentUser: ', currentUser);
			// Authenticate user..
			// Login the user here..
			
	}, []);

    console.log(user)
    const handleLogout = () => {
        setLogOutUser();
    
        removeCurrentUserLocalStorage();
      };
    const displaySubscriptions = () => {
        navigate('/subscriptions')
        setAuthenticateModal(false);
        setSubscriptions(true);
        console.log(todos);
    }

    return (
        <React.Fragment>
            
            {authenticateModal && <AuthenticateModal
                displaySubscriptions={displaySubscriptions}></AuthenticateModal>}
            
            
            </React.Fragment> 
           
    )
};

export default UserView;