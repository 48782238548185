// Libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Beta Components
import BetaHeader from "./BetaHeader";
import CollectionPreviewCard from "./CollectionPreviewCard";
import SalesFunnelBeta from "./SalesFunnelBeta";
import StartFree from "./StartFree";

// Live Components
import AuthenticateModal from "../../pages/authenticate-modal/AuthenticateModal";
import CheckoutModal from "../../pages/checkout-modal/CheckoutModal";
import AuthorizePaymentModal from "../authorize-payment/AuthorizePaymentModal";

// React icons
import { AiFillStar } from "react-icons/ai";
import { MdOutlineNavigateNext } from "react-icons/md";

// Redux Actions
import { logoutUser } from "../../redux/actions";

// Methods
import { removeCurrentUserLocalStorage, _ } from "../../lib";

// Constants
import { AUTHENTICATE, AUTHORIZE, CHECKOUT, MOBILE } from "../../lib/const";

// Stylesheets
import "./Beta.scss";
import "./BetaMobile.scss";
import "./CollectionPreviewCard.scss";
import "./CountDown.scss";
import StickyBar from "./StickyBar";

const Beta = ({ logoutUser, responsive, user }) => {
  const navigate = useNavigate();

  const [activeModal, setActiveModal] = useState(null);

  // Check if local storage has anything..

  const [createAccount, setCreateAccount] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  const [logoutUsers, setLogOutUser] = useState(false);

  const betaAssets = {
    //Demos new db - img
    "mac-model": `${process.env.REACT_APP_2_CDN_URL}/assets/img/mac.png`,
    "aspect-ratio": `${process.env.REACT_APP_2_CDN_URL}/assets/img/aspect-ratio.png`,
    "discord-logo": `${process.env.REACT_APP_2_CDN_URL}/assets/img/discord-logo.png`,
    "instagram-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/Instagram-icon.webp`,
    "aspect-ratio-new": `${process.env.REACT_APP_2_CDN_URL}/assets/img/aspect-ratio-new.png`,
    "how-to-install-1": `${process.env.REACT_APP_2_CDN_URL}/assets/img/how-to-install-1.png`,
    "how-to-install-2": `${process.env.REACT_APP_2_CDN_URL}/assets/img/how-to-install-2.png`,
    "settings-mac": `${process.env.REACT_APP_2_CDN_URL}/assets/img/settings-mac.png`,

    //Demos new db - img profile pic
    "profile-pic-edmun-tan": `${process.env.REACT_APP_2_CDN_URL}/assets/img/edmun-tan.jpg`,
    "profile-pic-george-orozco": `${process.env.REACT_APP_2_CDN_URL}/assets/img/george-orozco.jpg`,
    "profile-pic-junior-real": `${process.env.REACT_APP_2_CDN_URL}/assets/img/junior-real.jpg`,
    "profile-pic-justin-jones": `${process.env.REACT_APP_2_CDN_URL}/assets/img/justin-jones.jpg`,
    "profile-pic-nathan-blanco": `${process.env.REACT_APP_2_CDN_URL}/assets/img/nathan-blanco.jpg`,
    "profile-pic-ramon-gonzales": `${process.env.REACT_APP_2_CDN_URL}/assets/img/ramon-gonzales.jpg`,
    "profile-pic-rigo-santoyo": `${process.env.REACT_APP_2_CDN_URL}/assets/img/rigo-santoyo.jpg`,

    //Demos new db - img icons
    "crt-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/crt-icon.png`,
    "film-essentials-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/film-essentials-icon.png`,
    "motion-graphics-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/motion-graphics-icon.png`,
    "overlays-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/overlays-icon.png`,
    "post-production-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/post-production-icon.png`,
    "sound-design-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/sound-design-icon.png`,
    "text-presets-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/text-presets-icon.png`,
    "transitions-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/transitions-icon.png`,
    "vhs-emulator-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/vhs-emulator-icon.png`,
    "textures-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/textures-icon.png`,
    "updates-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/updates-icon.png`,

    "amex-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/amex-icon.png`,
    "applepay-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/applepay-icon.png`,
    "mastercard-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/mastercard-icon.png`,
    "paypal-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/paypal-icon.png`,
    "visa-icon": `${process.env.REACT_APP_2_CDN_URL}/assets/img/icons/visa-icon.png`,

    //Demos new db - mp4
    "directly-funnel-mobile": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/directly-funnel-mobile.mp4`,
    "showcase-demo-mobile": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/showcase-demo.mp4`,
    "textures-demo-mobile": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/textures-demo.mp4`,
    "asset-trio-mobile": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/asset-trio.mp4`,
    "square-extension-newsite-mp4": `${process.env.REACT_APP_2_CDN_URL}/assets/video/mp4/square-extension-newsite.mp4`,

    // Demos - webm
    "asset-trio": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.webm`,
    "showcase-demo": `${process.env.REACT_APP_CDN_URL}/home-page/showcase-demo.webm`,
    "text-demo": `${process.env.REACT_APP_CDN_URL}/home-page/text-demo.webm`,
    "textures-demo": `${process.env.REACT_APP_CDN_URL}/home-page/textures-demo.webm`,
    "transitions-demo": `${process.env.REACT_APP_CDN_URL}/home-page/transitions-demo.webm`,
    // Demos - mp4
    "asset-trio-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/asset-trio.mp4`,
    "showcase-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/showcase-demo.mp4`,
    "text-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/text-demo.mp4`,
    "textures-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/textures-demo.mp4`,
    "transitions-demo-mp4": `${process.env.REACT_APP_CDN_URL}/home-page/transitions-demo.mp4`,
    // More Demos - mp4
    crt: `${process.env.REACT_APP_CDN_URL}/home-page/crt.mp4`,
    "text-presets": `${process.env.REACT_APP_CDN_URL}/home-page/text-presets.mp4`,
    textures: `${process.env.REACT_APP_CDN_URL}/home-page/textures.mp4`,
    transitions: `${process.env.REACT_APP_CDN_URL}/home-page/transitions.mp4`,
    // Images
    "640-premiere-logo": `${process.env.REACT_APP_CDN_URL}/home-page/640-premiere-logo.png`,
    "activate-button": `${process.env.REACT_APP_CDN_URL}/home-page/activate-button.png`,
    edits: `${process.env.REACT_APP_CDN_URL}/home-page/edits.png`,
    "laptop-extension": `${process.env.REACT_APP_CDN_URL}/home-page/laptop-extension.png`,
    "mac-computer": `${process.env.REACT_APP_CDN_URL}/home-page/mac-computer.png`,
    overlays: `${process.env.REACT_APP_CDN_URL}/home-page/overlays.png`,
    texts: `${process.env.REACT_APP_CDN_URL}/home-page/texts.png`,
    transitions: `${process.env.REACT_APP_CDN_URL}/home-page/transitions.png`,
    updates: `${process.env.REACT_APP_CDN_URL}/home-page/updates.png`,
    "your-text-asset": `${process.env.REACT_APP_CDN_URL}/home-page/your-text-asset.png`,
  };

  const displayCheckoutModal = () => {
    setActiveModal(CHECKOUT);
  };

  const handleCloseModal = () => {
    console.log("handleCloseModal()");
    setActiveModal(null);
    setShowOverlay(false);
  };

  const handleLogin = () => {
    // Display 'Login' modal
    navigate("/user");
  };

  const handleLogout = () => {
    setLogOutUser();

    removeCurrentUserLocalStorage();
  };

  const handleStartFreeClick = () => {
    // Display 'Create Account' modal
    if (user && user.userId) navigate("/my-account");
    else navigate("/user?newUser=true",user);
  };

  // If screen display has not been determined, return
  if (!responsive) return;

  let staticPage = {
    overflow: "unset",
  };

  const isMobile = responsive === MOBILE;

 

  return (
    <div
      className={`beta${isMobile ? " mobile" : ""}`}
      style={showOverlay ? staticPage : null}
    >
      <div className={`${showOverlay ? "obscure-" : ""}wrapper`}>
        {/* Section 1 - Header Component */}

        <BetaHeader
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          handleClick={handleStartFreeClick}
          user={user}
          isTabs={false}
          responsive={responsive}
        />

        <div className="main-content">

          {/* Section 2 - Video Channel & Section 3 - Image w/ Female */}
          {/* <CountDown >*/}

          <SalesFunnelBeta
            handleStartFreeClick={handleStartFreeClick}
            background={betaAssets["edits"]}
            responsive={responsive}
          />
          

          {/* Section 4 - Directly Funnel */}
          {!isMobile ? (
            <div className="directly-funnel">
              <div className="content-wrapper">
                <div>
                  <h2>An extension for Adobe Premiere Pro.</h2>
                  <p>
                    Our integration with Adobe delivers the anticipated synergy
                    for editors.
                  </p>
                </div>

                <div className="video-wrapper">
                  <video autoPlay loop muted playsInline>
                    <source
                      src={betaAssets[`square-extension-newsite-mp4`]}
                      type={`video/${responsive === MOBILE ? "mp4" : "webm"}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                  <div>
                    <h3>
                      Exclusively for <br /> Adobe Premiere Pro users.
                    </h3>
                    <p>
                      We understand the importance of updating and curating
                      tools, which is why thousands of only the best creative
                      tools are being added monthly.
                    </p>
                    <br />
                    <p>
                      Simply activate your desired asset to seamlessly integrate
                      within Adobe Premiere Pro.
                    </p>
                    <br />
                    <p>Unlimited royalty-free assets at your fingertips.</p>
                  </div>
                </div>
                {/* <hr /> */}
              </div>
            </div>
          ) : (
            <div className="directly-funnel">
              <div className="section-title poppins-heading">
                <h3>{_("How it works:")}</h3>
              </div>
              <div className="content-wrapper">
                <div className="video-wrapper">
                  <video autoPlay loop muted playsInline>
                    <source
                      src={betaAssets[`directly-funnel-mobile`]}
                      type={`video/${responsive === MOBILE ? "mp4" : "webm"}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          )}

          {/* Section 5 - Constantly Updating & Evolving Funnel */}
          <div className="update-evolve-funnel">
            {!isMobile ? (
              <div className="funnel-main">
                <div className="top-section">
                  {/* <div className='icon'>
								<img
									alt='collection-icon'
									src={betaAssets['updates']}
								/>
							</div> */}
                  <div className="title poppins-heading">
                    {" "}
                    <h2>{_("Any aspect ratio")}</h2>
                  </div>
                  <div className="content">
                    <p>
                      {_(
                        "Our extension automatically recognizes yor timeline sequence settings and adjust accordingly."
                      )}
                    </p>
                  </div>
                </div>

                <div className="bottom-section">
                  <div className="assets-teaser">
                    {/* <video
									autoPlay
									loop
									muted
									playsInline
								>
									<source
										src={betaAssets['asset-trio-mp4']}
										type='video/mp4'
									/>
									Your browser does not support the video tag.
								</video> */}
                    <img src={betaAssets["aspect-ratio"]} alt="" />
                  </div>

                  <div className="info-section">
                    <img src={betaAssets["aspect-ratio-new"]} alt="" />
                    <div>
                      <h2>Dynamic for socials</h2>
                      <p>
                        We understand how important it is to create content for
                        socials so our extension supports Vertical and square
                        projects.
                      </p>
                      <h3>Smart Automation</h3>
                      <p>
                        The extension was developed to recognize your current
                        project settings and automatically adjust to your
                        sequence settings.
                      </p>
                      <p>
                        When adding an overlay for example, the extension will
                        scale or descale according to your current settings. The
                        extension will also automatically adjust the blend mode
                        to match your footage.
                      </p>
                      <p>
                        When adding transitions, the extension will adjust to
                        your aspect ratio, wether it’s Square, Vertical, Ultra
                        wide, or 8k.
                      </p>
                    </div>
                  </div>
                  <button
                    className="get-started-btn"
                    onClick={handleStartFreeClick}
                  >
                    Get started
                  </button>
                  {/* <hr /> */}
                </div>
              </div>
            ) : (
              <div className="assets-teaser-texts">
                <div>
                  <div>
                    <h2>All in one place</h2>
                    <p>Unlimited royalty-free assets at your fingertips.</p>
                    <p>
                      Download extension exclusively for Adobe Premiere Pro
                      users.
                    </p>
                  </div>
                  <img alt="collection-icon" src={betaAssets["mac-model"]} />
                </div>
              </div>
            )}
          </div>

          {/* Section 6 - All In One Place Funnel */}
          <div className="all-in-one-funnel">
            {!isMobile ? (
              <>
                <div className="all-in-one-funnel-content">
                  <h2>How to install</h2>
                  <p>
                    Before we start, make sure all of your Adobe programs are
                    closed
                  </p>
                  <div className="main-how-to">
                    <img
                      alt="collection-icon"
                      src={betaAssets["how-to-install-1"]}
                    />
                    <div>
                      <h3>Download ZXP Installer & 640 Extension</h3>
                      <p>Download ZXP Installer & 640 Extension</p>
                    </div>
                    <div>
                      <h3>Installation</h3>
                      <p>
                        Second drag the 640 Extension directly in ZXP installer
                        or open from the file menu. Installation may take a few
                        minutes. That’s it!{" "}
                      </p>
                    </div>
                    <img
                      alt="collection-icon"
                      src={betaAssets["how-to-install-2"]}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>Any aspect ratio</h2>
                <img alt="collection-icon" src={betaAssets["aspect-ratio"]} />
                <div>
                  <p>
                    Our extension automaticlly recognizes your timeline sequence
                    settings and adjusts accoordingly
                  </p>
                  <ul>
                    <li>
                      <strong>Dynamic for socials</strong>
                      <ul>
                        <li>
                          <p>
                            We understand how important it is to create content
                            for socials so our extension supports Vertical and
                            square projects.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Smart Automation</strong>
                      <ul>
                        <li>
                          {" "}
                          <p>
                            The extension was developed to recognize your
                            current project settings and automatically adjust to
                            your sequence settings.
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    When adding an overlay for example, the extension will scale
                    or descale according to your current settings. The extension
                    will also automatically adjust the blend mode to match your
                    footage.
                  </p>
                  <p>
                    When adding transitions, the extension will adjust to your
                    aspect ratio, wether it’s Square, Vertical, Ultra wide, or
                    8k.
                  </p>
                </div>
              </>
            )}
          </div>
          {!isMobile && (
            <>
              <div className="launch-funnel">
                <div className="launch-main">
                  <h2>Launch Adobe Premiere Pro</h2>
                  <p>
                    {_(
                      "To open extension with Adobe Premiere Pro, go to Window > Extension > 640 Studio Extension"
                    )}
                  </p>
                  <img src={betaAssets["settings-mac"]} alt="setting-mac" />
                </div>
              </div>
            </>
          )}
          {/* Section 7 - Exploration Funnel */}
          <div className="exploration-funnel">
            {!isMobile ? (
              <div className="main-exploration">
                <h2>Watch Demo</h2>
                <iframe
                  width="80%"
                  height="500"
                  src="https://www.youtube.com/embed/QuXHS4gm9Ms"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <p>
                  640 is always getting faster and easier to use.
                  <br />
                  You’ll only need to install once, then you can simply login
                  via Adobe Premiere.
                  <br />
                  Let's set up your extension.
                </p>
                <button
                  className="get-started-btn"
                  onClick={handleStartFreeClick}
                >
                  Get started
                </button>
              </div>
            ) : (
              <>
                <div>
                  <h2>Watch Demo</h2>
                  <iframe
                    width="560"
                    height="200"
                    src="https://www.youtube.com/embed/QuXHS4gm9Ms"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <p>
                    640 is always getting faster and easier to use. You’ll only
                    need to install once, then you can simply login via Adobe
                    Premiere.
                  </p>
                  {/* <button className='getStarted-btn' onClick={handleStartFreeClick}>
									<p>Get started</p>
								</button> */}
                  <button
                    className="getStarted-btn"
                    onClick={handleStartFreeClick}
                  >
                    Get Started
                  </button>
                </div>
              </>
            )}
          </div>
          {/* Section Join the community*/}
          {!isMobile && (
            <>
              <div className="join-the-community-funnel">
                <h2>Join the community</h2>
                <div className="social-networks">
                  <div>
                    <a href="https://discord.gg/J27g74mvsa" target={"_blank"}>
                      <img
                        alt="collection-icon"
                        src={betaAssets["discord-logo"]}
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/sixfortystudio"
                      target={"_blank"}
                    >
                      <img
                        alt="collection-icon"
                        src={betaAssets["instagram-icon"]}
                      />
                    </a>
                  </div>

                  <h3>Constantly updating & evolving</h3>
                  <p>
                    We understand the importance of updating and curating tools,
                    which is why thousands of only the best creative tools are
                    being added monthly.
                  </p>
                </div>
                <div />

                <div className="cards">
                  <div className="one">
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-george-orozco`]
                            }")`,
                          }}
                        ></div>
                        <h3>George orozco</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">Director @george_orozco</p>
                      <p>
                        Love that i don’t have to install the effect on every
                        project i open up. It’s just a login and it’s
                        available for me to use.
                      </p>
                    </div>
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-justin-jones`]
                            }")`,
                          }}
                        ></div>
                        <h3>Justin Jones</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">
                        Cinematographer @justinjonesdp
                      </p>
                      <p>
                        Super legit and useful extension, I use it all the time.
                        You can't beat it especially at this price point.
                      </p>
                    </div>
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-junior-real`]
                            }")`,
                          }}
                        ></div>
                        <h3>Junior Real</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">DP @juniorshotyou</p>
                      <p>
                        Amazing extension, works as intended and
                        gets the job done.
                      </p>
                    </div>
                  </div>
                  <div className="two">
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-rigo-santoyo`]
                            }")`,
                          }}
                        ></div>
                        <h3>Rigo Santoyo</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">DP @rigo_the_director</p>
                      <p>
                        Was really surprised how well this integrates with
                        premiere. Never seen anything like it.
                      </p>
                    </div>
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-ramon-gonzales`]
                            }")`,
                          }}
                        ></div>
                        <h3>Mateo Ramon</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">Editor @directedbyteo</p>
                      <p>
                        My favorite thing about this product is how it automates
                        all the presets for me. My turn around time is a
                        lot quicker now.
                      </p>
                    </div>
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-nathan-blanco`]
                            }")`,
                          }}
                        ></div>
                        <h3>Nathan Blanco</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">Editor @nathan_blanco</p>
                      <p>
                        The annoying thing about effects packs is that you have
                        to figure out how to install, and apply presets.
                        Sometimes you find yourself wasting way more time
                        figuring out these things out than using it. This
                        extension is a one time install that works
                        automatically all the time.
                      </p>
                    </div>
                    <div className="review-card">
                      <div className="username">
                        <div
                          className="user-pic"
                          style={{
                            backgroundImage: `url("${
                              betaAssets[`profile-pic-edmun-tan`]
                            }")`,
                          }}
                        ></div>
                        <h3>Edmund Tan</h3>
                        <div className="stars">
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                          <AiFillStar size={16} />
                        </div>
                      </div>
                      <p className="user-profession">Editor @edmundtanfilms</p>
                      <p>
                        Great extension for premiere editors, wouldn’t recommend
                        anything else. Especially with updates, I get new
                        effects in premiere monthly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Section - requirements */}
          {/* {!isMobile&&
						<div className='requirements-funnel'>
							<p>Requirements:
								<br />
								Adobe Premiere Pro (not cracked)
								<br />
								At least 30 GB of free space before installing
								<br />
							</p>
						</div>
					} */}
          {/* Section 8 - Get Started Funnel */}
          <div className="get-started-funnel">
            {!isMobile ? (
              <div className="funnel-main">
                <div className="one">
                  <h3>Requirements:</h3>
                  <p>
                    Adobe Premiere Pro (not cracked)
                    <br />
                    At least 30 GB of free space before installing
                    <br />
                  </p>
                </div>
                <div className="two">
                  <h2>DaVinci Resolve</h2>
                  <p>
                    We are currently developing this extension for DaVinci
                    Resolve users!
                    <br />
                    Submit your email below to be notified when it realeases.
                  </p>
                  <form
                    className="notify-email-input"
                    onSubmit={(el) => {
                      el.preventDefault();
                    }}
                  >
                    <input type="email" name="email" id="NotiEmail" required />
                    <button>
                      <MdOutlineNavigateNext size={25} />
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <>
                <div className="get-started-vid">
                  <video
                    className="get-started-vid-1"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source
                      src={betaAssets[`asset-trio-mobile`]}
                      type={`video/${responsive === MOBILE ? "mp4" : "webm"}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="get-started-items">
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`transitions-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Transitions</h2>
                    <p>
                      Scrub through thousands of transitions simply double click
                      to add your favorite.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`text-presets-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Text presets</h2>
                    <p>
                      Hundreds of presets to choose from, lower thirds, pan
                      motions, vintage text and cine titles.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`overlays-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Overlays</h2>
                    <p>
                      CRT scans, VHS, Film essentials, Film grains,
                      Burns and many more.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`text-presets-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Cine title</h2>
                    <p>
                      Movie inspired pre made titles with vintage controls.
                      Fully customizable with fonts included.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`motion-graphics-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Motion Graphics</h2>
                    <p>
                      phone pop ups, text messages, icons, subscribe buttons,
                      instagram geo tags are fully customizable
                      within the extension.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`post-production-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Post production filters</h2>
                    <p>
                      Forget to film it? do it in post. Diffusion filters, fish
                      eye lenses, 4K anamorphic lens flares etc.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`film-essentials-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Film essentials</h2>
                    <p>
                      Everything vintage such as motion film mattes, grains,
                      burns, leaks, scratches, dust, hairs, and many more.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`sound-design-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Sound design</h2>
                    <p>
                      Cinematic, Horror, Action, Foley, Explosions, Wooshes,
                      Drones, drops, bass, etc.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${betaAssets[`crt-icon`]}")`,
                      }}
                    ></div>
                    <h2>CRT</h2>
                    <p>
                      Abstract overlays, fonts, numbers, symbols, text presets
                      and vintage controls.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`textures-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>Textures</h2>
                    <p>
                      Tape, wrapper texture, paper texture, High quality
                      PNG with alpha.
                    </p>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url("${
                          betaAssets[`vhs-emulator-icon`]
                        }")`,
                      }}
                    ></div>
                    <h2>VHS Emulation</h2>
                    <p>
                      Tape vhs sound effects, static, overlays, post
                      production add-ons.
                    </p>
                  </div>
                  <div className="get-started-items-last">
                    <div
                      style={{
                        backgroundImage: `url("${betaAssets[`updates-icon`]}")`,
                      }}
                    ></div>
                    <h2>Updates</h2>
                    <p>
                      Scrub through thousands of transitions simply double click
                      to add your favorite.
                    </p>
                  </div>
                </div>
              </>
            )}
            {/* Section 9 - footer (Last One) (Only in mobile) */}
            {isMobile && (
              <>
                <footer className="footer-mobile">
                  <div className="footer-mobile-main">
                    <div className="footer-mobile-info-txt">
                      <h2>Join the comunity</h2>
                      <div>
                        <a href="https://discord.gg/Gebxj65N" target={"_blank"}>
                          <img
                            alt="collection-icon"
                            src={betaAssets["discord-logo"]}
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/sixfortystudio"
                          target={"_blank"}
                        >
                          <img
                            alt="collection-icon"
                            src={betaAssets["instagram-icon"]}
                          />
                        </a>
                      </div>

                      <h2>Constantly updating & evolving</h2>
                      <p>
                        We understand the importance of updating and curating
                        tools, which is why thousands of only the best creative
                        tools are being added monthly.
                      </p>
                    </div>
                    <button
                      className="getStarted-btn"
                      onClick={handleStartFreeClick}
                    >
                      Get started
                    </button>

                    {/* <p className='footer-mobile-freeTrial'>7 day free trial</p> */}
                    <br />
                    <ul className="footer-mobile-requirements">
                      <li>
                        Requirements:
                        <ul>
                          <li>Adobe Premiere Pro (not cracked)</li>
                          <li>At least 30g of free space before installing</li>
                          <li>Works on Mac & PC</li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="footer-mobile-privacy-terms">
                    <div>
                      <div>
                        <Link to="/privacy-policy">{_("PRIVACY POLICY")}</Link>
                      </div>
                      <div>
                        <Link to="/refund-policy">{_("REFUND POLICY")}</Link>
                      </div>
                      <div>
                        <Link to="/delivery-policy">
                          {_("DELIVERY POLICY")}
                        </Link>
                      </div>
                      <div>
                        <Link to="/contact">{_("CONTACT US")}</Link>
                      </div>
                      <div>
                        <Link to="/terms-of-service">
                          {_("TERMS OF SERVICE")}
                        </Link>
                      </div>
                    </div>

                    <div className="footer-mobile-privacy-terms-payMethods">
                      <img
                        alt="collection-icon"
                        src={betaAssets["amex-icon"]}
                      />
                      <img
                        className="white-icon"
                        alt="collection-icon"
                        src={betaAssets["mastercard-icon"]}
                      />
                      <img
                        className="white-icon"
                        alt="collection-icon"
                        src={betaAssets["paypal-icon"]}
                      />
                      <img
                        className="white-icon"
                        alt="collection-icon"
                        src={betaAssets["visa-icon"]}
                      />
                      <img
                        alt="collection-icon"
                        src={betaAssets["applepay-icon"]}
                      />
                    </div>
                    <p>© 2023 640 STUDIO.</p>
                  </div>
                </footer>
              </>
            )}
          </div>

          {/* Section 9 - Footer */}
          <div className="footer">
            <div className="footer-wrapper">
              <div className="footer-link">
                <Link to="/contact">{_("Contact Us")}</Link>
              </div>

              <div className="footer-link">
                <Link to="/privacy-policy">{_("Privacy Policy")}</Link>
              </div>

              <div className="footer-link">
                <Link to="/refund-policy">{_("Refund Policy")}</Link>
              </div>

              <div className="footer-link">
                <Link to="/delivery-policy">{_("Delivery Policy")}</Link>
              </div>

              <div className="footer-link">
                <Link to="/terms-of-service">{_("Terms of Service")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showOverlay && activeModal === AUTHENTICATE && (
        <AuthenticateModal
          createAccount={createAccount}
          clickCloseModal={handleCloseModal}
          displayCheckoutModal={displayCheckoutModal}
        />
      )}

      {/* Only Show Checkout Modal If User Account Authenticated */}
      {showOverlay && activeModal === CHECKOUT && user.isAuthenticated && (
        <CheckoutModal clickCloseModal={handleCloseModal} />
      )}

      {showOverlay && (activeModal === AUTHORIZE) & user.isAuthenticated && (
        <AuthorizePaymentModal clickCloseModal={handleCloseModal} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    responsive: state.responsive.responsive,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Beta);

// VV - Notes:
// Figure out how to hook in to the modal-hook
// Get any modal passed in there and render nicely
// <Modal>Hi</Modal>

// More Cleanup To Do:
// Make Each Section Into It's Own Component w/ Own Stylesheet
// Make Class Names Better & More Intuitive, follow the content
// Tweak CSS Stylings, Make Better, Clean Up
// Minimize/Compress All Images & Make Lightning Fast
