// Libraries
import React from 'react';

// Components
import SoundCardList from '../../components/sound-card-list/SoundCardList';

// Stylesheets
import './SoundDesign.scss';

const SoundDesign = props => {
	const {
		data,
		handleScroll,
		name,
	} = props;

	return (
		<div
			className='sound-design'
			onScroll={handleScroll}
		>
			<SoundCardList
				data={data}
				name={name}
			/>
		</div>
	);
};

export default SoundDesign;